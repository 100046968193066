import React, { useState } from "react";
import {
  Button,
  TextField,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Alert,
} from "@mui/material";
import { postApi } from "services/axiosInstance"; // Assuming this handles API calls.
import { API_PATH } from "services/apipath"; // Adjust as needed.

const PTEEmailWritingModule = () => {
  const [to, setTo] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [wordCount, setWordCount] = useState(0);
  const [scores, setScores] = useState({
    content: 0,
    form: 0,
    grammar: 0,
    vocabulary: 0,
    spelling: 0,
    development: 0,
    conventions: 0,
    organisation: 0,
  });

  // Keywords for relevancy checking
  const relevantKeywords = [
    "hometown ",
    "Furthermore",
    "Friday",
    "restaurant",
    "fantastic",
    "neighbourhoods",
  ];

  // Check Relevancy of the email body
  const checkRelevancy = (text) => {
    const textLower = text.toLowerCase();
    const foundKeywords = relevantKeywords.filter((keyword) =>
      textLower.includes(keyword.toLowerCase())
    );
    return {
      isRelevant: foundKeywords.length >= 2, // Assume 2 keywords are required
      keywordCount: foundKeywords.length,
      foundKeywords,
    };
  };

  // Evaluate Grammar, Spelling, and other aspects
  const evaluateText = async () => {
    setLoading(true);
    try {
      const response = await postApi(API_PATH.STUDENTS.CHECK_GRAMMAR, {
        text: body,
      });

      const grammarErrors = response.data.result.edits;
      const grammarResult = response.data.result;
      const relevancy = checkRelevancy(body);
      const errorPenalty = grammarErrors.length * 5;

      const calculateConventions = () => {
        if (!grammarErrors) return 90;
        const conventionErrors = grammarErrors.filter(
          (edit) => edit.err_cat === "PUNCT" || edit.edit_type === "FORMAT"
        );
        return Math.max(0, 90 - conventionErrors.length * 3);
      };

      const calculateOrganization = () => {
        let score = 90;
        const paragraphs = body.split("\n\n").filter((p) => p.trim());

        if (paragraphs.length < 3) score -= 20;
        if (!body.includes("\n")) score -= 10;

        const transitions = [
          "however",
          "moreover",
          "furthermore",
          "therefore",
          "consequently",
        ];
        const hasTransitions = transitions.some((word) =>
          body.toLowerCase().includes(word)
        );
        if (!hasTransitions) score -= 10;

        // Additional penalty for organizational errors from API
        const organizationErrors = grammarErrors.filter(
          (e) => e.rule?.category === "ORGN"
        );
        score -= organizationErrors.length * 5;

        return Math.max(0, score);
      };

      let newScores = {
        content: Math.max(0, 90 - errorPenalty),
        form: Math.max(0, 90 - errorPenalty / 2),
        grammar: Math.max(
          0,
          90 -
            grammarErrors.filter((e) => e.rule?.category === "GRAMMAR").length *
              10
        ),
        vocabulary: Math.max(
          0,
          (relevancy.keywordCount / relevantKeywords.length) * 100
        ),
        spelling: Math.max(
          0,
          90 -
            grammarErrors.filter((e) => e.rule?.category === "SPELLING")
              .length *
              10
        ),
        development: body.split(/\s+/).length >= 50 ? 90 : 70, // Assume 50 words for good development
        // conventions: Math.max(0, 90 - grammarErrors.filter((e) => e.rule?.category === "CONV").length * 5),
        // organisation: Math.max(0, 90 - grammarErrors.filter((e) => e.rule?.category === "ORGN").length * 5),
        conventions: calculateConventions(),
        organisation: calculateOrganization(),
      };

      setScores(newScores);
      setErrors(grammarErrors);
      setWordCount(body.split(/\s+/).length);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  // Display Errors in the email body
  const HighlightedText = ({ text, errors }) => {
    let displayText = text;
    const textParts = [];
    let lastIndex = 0;

    // Sort errors by offset to process them in order
    const sortedErrors = [...errors].sort((a, b) => a.start - b.start);

    sortedErrors.forEach((error) => {
      const { start, end, replace, err_cat } = error;
      const errorType = err_cat === "GRMR" ? "text-warning" : "text-danger";

      if (start > lastIndex) {
        textParts.push(
          <span key={`normal-${lastIndex}`} className="text-success">
            {text.slice(lastIndex, start)}
          </span>
        );
      }

      textParts.push(
        <span key={`error-${start}`} className={`p-1 ${errorType}`}>
          {replace || text.slice(start, end)}
        </span>
      );

      lastIndex = end;
    });

    if (lastIndex < text.length) {
      textParts.push(
        <span key={`normal-end`} className="text-success">
          {text.slice(lastIndex)}
        </span>
      );
    }

    return <div className="bg-light p-2 rounded">{textParts}</div>;
  };

  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-12">
          <h2 className="text-center mb-4">PTE Email Writing Evaluation</h2>
        </div>

        <div className="col-12 mb-4">
          <div className="d-flex justify-content-between align-items-center">
            <h5>Compose Your Email</h5>
          </div>

          {/* Email Input Fields */}
          {/* <TextField
            label="To"
            variant="outlined"
            fullWidth
            value={to}
            onChange={(e) => setTo(e.target.value)}
            className="my-3"
          />
          <TextField
            label="Subject"
            variant="outlined"
            fullWidth
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            className="my-3"
          /> */}
          <TextField
            label="Body"
            variant="outlined"
            multiline
            rows={6}
            fullWidth
            value={body}
            onChange={(e) => setBody(e.target.value)}
            className="my-3"
          />

          {/* Submit Button */}
          <Button
            onClick={evaluateText}
            disabled={loading || !body}
            variant="contained"
            color="primary"
            className="w-100"
          >
            {loading ? "Evaluating..." : "Evaluate Email"}
          </Button>

          {/* Display Evaluation Results */}
          {loading && <CircularProgress />}

          {/* Display Errors and Text Highlighting */}
          {body && (
            <div className="mt-4">
              <HighlightedText text={body} errors={errors} />
            </div>
          )}

          {/* Display Alerts for Feedback */}
          {checkRelevancy(body).isRelevant === false && (
            <Alert severity="error">
              Content is not relevant to the topic.
            </Alert>
          )}

          {body.split(/\s+/).length < 50 && (
            <Alert severity="warning">Your email needs more development.</Alert>
          )}
        </div>

        {/* Display Scores */}
        <div className="col-12 mt-4">
          <Card>
            <CardHeader title="Evaluation Scores" />
            <CardContent>
              <div>
                <div>
                  <strong>Content:</strong> {scores.content}
                </div>
                <div>
                  <strong>Form:</strong> {scores.form}
                </div>
                <div>
                  <strong>Grammar:</strong> {scores.grammar}
                </div>
                <div>
                  <strong>Vocabulary:</strong> {scores.vocabulary}
                </div>
                <div>
                  <strong>Spelling:</strong> {scores.spelling}
                </div>
                <div>
                  <strong>Development:</strong> {scores.development}
                </div>
                <div>
                  <strong>Conventions:</strong> {scores.conventions}
                </div>
                <div>
                  <strong>Organisation:</strong> {scores.organisation}
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default PTEEmailWritingModule;

// import { AlertCircle, CheckCircle2, XCircle } from 'lucide-react';

// const AdvancedPTEScorer = () => {
//   const [emailText, setEmailText] = useState('');
//   const [keywords, setKeywords] = useState('');
//   const [sampleAnswer, setSampleAnswer] = useState('');
//   const [scores, setScores] = useState(null);
//   const [isLoading, setIsLoading] = useState(false);
//   const [grammarResult, setGrammarResult] = useState(null);

//   const checkGrammar = async () => {
//     setIsLoading(true);
//     try {
//       const response = await fetch("https://grammarbot-neural.p.rapidapi.com/v1/check", {
//         method: "POST",
//         headers: {
//           "x-rapidapi-key":'2ae05a19cemsh2195e8d91a51199p18d847jsn30ea8fc37b60',
//           "x-rapidapi-host": 'grammarbot-neural.p.rapidapi.com',
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ text: emailText }),
//       });

//       const result = await response.json();
//       setGrammarResult(result.result);
//       return calculateGrammarScore(result.result.edits);
//     } catch (error) {
//       return 90; // Fallback score
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const calculateGrammarScore = (edits) => {
//     if (!edits || edits.length === 0) return 90;

//     // Calculate deductions based on error categories
//     const deductions = edits.reduce((total, edit) => {
//       switch (edit.err_cat) {
//         case 'GRMR':
//           return total + 3; // Grammar errors
//         case 'SPELL':
//           return total + 2; // Spelling errors
//         case 'PUNCT':
//           return total + 1; // Punctuation errors
//         default:
//           return total + 1;
//       }
//     }, 0);

//     return Math.max(0, 90 - deductions);
//   };

//   const calculateScore = async () => {
//     const grammarScore = await checkGrammar();

//     const scoreContent = () => {
//       let score = 75;
//       const keywordList = keywords.toLowerCase().split(',').map(k => k.trim());
//       const matchedKeywords = keywordList.filter(keyword =>
//         emailText.toLowerCase().includes(keyword)
//       );
//       const keywordScore = (matchedKeywords.length / keywordList.length) * 25;
//       return Math.min(100, score + keywordScore);
//     };

//     const scoreForm = () => {
//       let score = 82.5;
//       const hasProperGreeting = /^(dear|hello|hi)/i.test(emailText);
//       const hasProperClosing = /(regards|sincerely|yours truly)/i.test(emailText);
//       const paragraphs = emailText.split('\n\n').filter(p => p.trim());

//       if (!hasProperGreeting) score -= 10;
//       if (!hasProperClosing) score -= 10;
//       if (paragraphs.length < 3) score -= 10;

//       return Math.max(0, score);
//     };

//     const scoreVocabulary = () => {
//       const words = new Set(emailText.toLowerCase().split(/\s+/));
//       const uniqueWords = words.size;
//       return (uniqueWords / 100) * 16.67; // Normalized to match your specified base score
//     };

//     const scoreSpelling = () => {
//       if (!grammarResult?.edits) return 90;
//       const spellingErrors = grammarResult.edits.filter(edit => edit.err_cat === 'SPELL');
//       return Math.max(0, 90 - (spellingErrors.length * 5));
//     };

//     const scoreDevelopment = () => {
//       let score = 90;

//       if (sampleAnswer) {
//         const samplePoints = sampleAnswer.toLowerCase().split('.').length;
//         const userPoints = emailText.toLowerCase().split('.').length;
//         const developmentRatio = userPoints / samplePoints;

//         if (developmentRatio < 0.7) score -= 20;
//         else if (developmentRatio < 0.8) score -= 10;
//       }

//       const wordCount = emailText.split(/\s+/).length;
//       if (wordCount < 150) score -= 15;
//       if (wordCount < 100) score -= 15;

//       return Math.max(0, score);
//     };

//     const scoreConventions = () => {
//       if (!grammarResult?.edits) return 90;
//       const conventionErrors = grammarResult.edits.filter(edit =>
//         edit.err_cat === 'PUNCT' || edit.edit_type === 'FORMAT'
//       );
//       return Math.max(0, 90 - (conventionErrors.length * 3));
//     };

//     const scoreOrganisation = () => {
//       let score = 90;
//       const paragraphs = emailText.split('\n\n').filter(p => p.trim());

//       if (paragraphs.length < 3) score -= 20;
//       if (!emailText.includes('\n')) score -= 10;

//       const transitions = ['however', 'moreover', 'furthermore', 'therefore', 'consequently'];
//       const hasTransitions = transitions.some(word => emailText.toLowerCase().includes(word));
//       if (!hasTransitions) score -= 10;

//       return Math.max(0, score);
//     };

//     setScores({
//       content: scoreContent(),
//       form: scoreForm(),
//       grammar: grammarScore,
//       vocabulary: scoreVocabulary(),
//       spelling: scoreSpelling(),
//       development: scoreDevelopment(),
//       conventions: scoreConventions(),
//       organisation: scoreOrganisation()
//     });
//   };

//   const GrammarErrorList = () => (
//     <div className="mt-4">
//       <h3 className="font-semibold mb-2">Corrections Needed:</h3>
//       <ul className="space-y-2">
//         {grammarResult?.edits.map((edit, index) => (
//           <li key={index} className="text-sm">
//             <span className="font-medium">{edit.err_cat}:</span>
//             <div className="text-xs text-gray-600">
//               Replace "{emailText.substring(edit.start, edit.end)}" with "{edit.replace}"
//             </div>
//           </li>
//         ))}
//       </ul>
//       {grammarResult?.correction && (
//         <div className="mt-4">
//           <h4 className="font-semibold mb-2">Suggested Correction:</h4>
//           <pre className="whitespace-pre-wrap text-sm bg-gray-50 p-4 rounded">
//             {grammarResult.correction}
//           </pre>
//         </div>
//       )}
//     </div>
//   );

//   return (
//     <div className="w-full max-w-4xl mx-auto space-y-4">
//       <Card>
//         <CardHeader>
//           <p>Advanced PTE Email Scorer</p>
//         </CardHeader>
//         <CardContent className="space-y-4">
//           <div>
//             <label className="block mb-2 font-medium">Keywords (comma-separated)</label>
//             <input
//               type="text"
//               className="w-full p-2 border rounded-md"
//               value={keywords}
//               onChange={(e) => setKeywords(e.target.value)}
//               placeholder="Enter keywords..."
//             />
//           </div>

//           <div>
//             <label className="block mb-2 font-medium">Sample Answer (for development scoring)</label>
//             <textarea
//               className="w-full h-32 p-2 border rounded-md"
//               value={sampleAnswer}
//               onChange={(e) => setSampleAnswer(e.target.value)}
//               placeholder="Enter sample answer..."
//             />
//           </div>

//           <div>
//             <label className="block mb-2 font-medium">Your Email</label>
//             <textarea
//               className="w-full h-64 p-2 border rounded-md"
//               value={emailText}
//               onChange={(e) => setEmailText(e.target.value)}
//               placeholder="Enter your email text..."
//             />
//           </div>

//           <button
//             className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
//             onClick={calculateScore}
//           >
//             Score Email
//           </button>
//         </CardContent>
//       </Card>
//       {GrammarErrorList()}

//       {scores && (
//         <Card>
//           <CardHeader>
//             <p>Detailed Scoring Results</p>
//           </CardHeader>
//           <CardContent>
//             <div className="grid grid-cols-2 gap-4">
//               {Object.entries(scores).map(([criterion, score]) => (
//                 <div key={criterion} className="p-4 border rounded-lg">
//                   <h3 className="font-semibold capitalize">{criterion}</h3>
//                   <div className="flex items-center gap-2">
//                     {/* <p className={getScoreColor(score)}>{score.toFixed(1)}%</p> */}
//                     {score >= 80 ? (
//                       <CheckCircle2 className="h-4 w-4 text-green-600" />
//                     ) : score >= 65 ? (
//                       <AlertCircle className="h-4 w-4 text-yellow-600" />
//                     ) : (
//                       <XCircle className="h-4 w-4 text-red-600" />
//                     )}
//                   </div>
//                 </div>
//               ))}
//             </div>
//           </CardContent>
//         </Card>
//       )}
//     </div>
//   );
// };

// export default AdvancedPTEScorer;

import {
  Box,
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeDownIcon from "@mui/icons-material/VolumeDown";

const AudioPlayer = ({ audioText, delay }) => {
  const [repeatCount, setRepeatCount] = useState(1);
  const paragraph = audioText;
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [selectedVoice, setSelectedVoice] = useState("null");
  const [volume, setVolume] = useState(1);
  const [rate, setRate] = useState(0.88);

  const [playbackRate, setPlaybackRate] = useState(1);
  const [isHoveringVolume, setIsHoveringVolume] = useState(false);

  const formatRate = (value) => `${value.toFixed(1)}x`;

  const speechRef = useRef(null);
  const counterRef = useRef(0);
  const progressIntervalRef = useRef(null);
  const progressBarRef = useRef(null);
  const seekTimeRef = useRef(0);
  const speechQueueRef = useRef([]);
  const gender = Math.random() < 0.5 ? "female" : "male";

  useEffect(() => {
    const loadVoices = () => {
      const availableVoices = speechSynthesis.getVoices(); 
      // Randomly select between male and female voices based on the input gender
      if (gender === "male") {
        return setSelectedVoice(null);
      } else if (gender === "female") {
        setSelectedVoice(
          availableVoices.find(
            (voice) => voice.name === "Microsoft Zira - English (United States)"
          ) || availableVoices[0]
        );
      } else {
        // If no voices available, return the first available voice
        return availableVoices[0];
      }

      // Microsoft Zira - English (United States)
      // Microsoft David - English (United States)
      // Google UK English Female
      // Google UK English Male
      // const femaleVoice =
      //   availableVoices.find(
      //     (voice) => voice.name === "Microsoft Zira - English (United States)"
      //   ) || availableVoices[0];
      // if (femaleVoice) {
      //   setSelectedVoice(femaleVoice);
      // }
    };

    speechSynthesis.onvoiceschanged = loadVoices;
    loadVoices();

    return () => {
      speechSynthesis.cancel();
      if (progressIntervalRef.current) {
        clearInterval(progressIntervalRef.current);
      }
    };
  }, []);

  const [preparationTimer, setPreparationTimer] = useState(delay);
  const [timerPhase, setTimerPhase] = useState("preparation");

  useEffect(() => {
    setPreparationTimer(delay);
    setTimerPhase("preparation");
  }, [audioText]);

  useEffect(() => {
    if (timerPhase !== "preparation" || preparationTimer <= 0) return;

    const intervalId = setInterval(() => {
      setPreparationTimer((prev) => {
        if (prev <= 1) {
          setTimerPhase("recording");
          repeatParagraph();
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [preparationTimer, timerPhase]);

  // useEffect(() => {
  //   const loadVoices = () => {
  //     const availableVoices = speechSynthesis.getVoices();

  //     setVoices(availableVoices);

  //     // Filter voices for UK and US accents (both male and female)
  //     const ukVoices = availableVoices.filter(
  //       (voice) => voice.lang === "en-AU"
  //     );
  //     console.log(ukVoices ,"ukVoices")
  //     const usVoices = availableVoices.filter(
  //       (voice) => voice.lang === "en-US"
  //     );

  //     // If UK voices exist, select a random one (prefer UK accent)
  //     if (ukVoices.length > 0) {
  //       const randomUkVoice =
  //         ukVoices[Math.floor(Math.random() * ukVoices.length)];
  //       setSelectedVoice(randomUkVoice);
  //     } else if (usVoices.length > 0) {
  //       // Fallback to US voices if UK voices aren't found
  //       const randomUsVoice =
  //         usVoices[Math.floor(Math.random() * usVoices.length)];
  //         console.log(randomUsVoice)
  //       setSelectedVoice(randomUsVoice);
  //     }
  //   };

  //   // Trigger voice loading after page load
  //   speechSynthesis.onvoiceschanged = loadVoices;
  //   loadVoices();

  //   return () => {
  //     speechSynthesis.cancel();
  //   };
  // }, [audioText]);

  const createProgressTracker = (duration) => {
    if (progressIntervalRef.current) {
      clearInterval(progressIntervalRef.current);
    }

    let elapsedTime = 0;
    progressIntervalRef.current = setInterval(() => {
      elapsedTime += 55;
      const progressPercentage = Math.min((elapsedTime / duration) * 100, 100);
      setProgress(progressPercentage);

      if (elapsedTime >= duration) {
        clearInterval(progressIntervalRef.current);
      }
    }, 55);
  };

  const stopProgressTracking = () => {
    if (progressIntervalRef.current) {
      clearInterval(progressIntervalRef.current);
    }
    setProgress(0);
  };

  const repeatParagraph = () => {
    if (speechSynthesis.speaking || isPlaying || repeatCount <= 0) return;

    counterRef.current = 0;
    speechQueueRef.current = [];

    const startSpeech = () => {
      localStorage.setItem("player", "playing");
      const speech = new SpeechSynthesisUtterance(paragraph);
      const wordCount = paragraph.trim().split(/\s+/).length;
      const estimatedDuration = Math.max(
        wordCount * 300,
        paragraph.length * 50
      );

      // const durationInSeconds = (wordCount / 300) * 60;
      // const estimatedDuration = durationInSeconds * 1000

      // speech.voice = selectedVoice || voices[0];
      if (gender === "male") {
        speech.lang = "en-AU";
      }
      if (gender === "female") {
        speech.voice = selectedVoice;
      }

      // TTSWeb.rate = 0.88;
      speech.volume = volume; // Set volume dynamically
      speech.rate = rate; // Set rate dynamically
      speechRef.current = speech;

      speech.onstart = () => {
        setIsPlaying(true);
        createProgressTracker(estimatedDuration);
      };

      speech.onend = () => {
        counterRef.current++;
        if (counterRef.current < repeatCount) {
          createProgressTracker(estimatedDuration);
          speechSynthesis.speak(speech);
        } else {
          setIsPlaying(false);
          stopProgressTracking();
        }
      };

      speechQueueRef.current.push(speech);
      speechSynthesis.speak(speech);
    };
    startSpeech();
  };

  // const repeatParagraph = () => {
  //   // Check if SpeechSynthesis is available
  //   if (!('speechSynthesis' in window)) {
  //     alert('Speech synthesis is not supported in this browser.');
  //     return;
  //   }

  //   // Prevent starting speech if it's already playing or other condition is met
  //   if (speechSynthesis.speaking || isPlaying || repeatCount <= 0) {
  //     return;
  //   }

  //   counterRef.current = 0;
  //   speechQueueRef.current = [];

  //   const startSpeech = () => {
  //     // Check if paragraph is not empty
  //     if (!paragraph.trim()) {
  //       alert('No text provided for speech.');
  //       return;
  //     }

  //     const speech = new SpeechSynthesisUtterance(paragraph);
  //     const wordCount = paragraph.trim().split(/\s+/).length;
  //     const estimatedDuration = Math.max(wordCount * 300, paragraph.length * 50);

  //     // Check available voices
  //     const voices = speechSynthesis.getVoices();

  //     // Use the selected voice or fallback to the first available voice
  //     if (!selectedVoice && voices.length > 0) {
  //       selectedVoice = voices[0]; // Fallback to the first available voice
  //     }

  //     speech.voice = selectedVoice;
  //     speech.volume = volume; // Set volume dynamically
  //     speech.rate = rate; // Set rate dynamically
  //     speechRef.current = speech;

  //     // Debugging: Log the speech object

  //     let startTime = 0;
  //     let progressInterval;

  //     speech.onstart = () => {

  //       setIsPlaying(true);
  //       startTime = Date.now();  // Track the actual start time

  //       // Start the progress tracker using setInterval
  //       progressInterval = setInterval(() => {
  //         const elapsedTime = Date.now() - startTime;
  //         const progressPercentage = Math.min((elapsedTime / estimatedDuration) * 100, 100);
  //         setProgress(progressPercentage);

  //       }, 50); // Update progress every 50ms

  //       // Begin speech playback
  //       speechSynthesis.speak(speech);
  //     };

  //     speech.onend = () => {

  //       // Stop progress tracking when the speech ends
  //       clearInterval(progressInterval);

  //       counterRef.current++;
  //       if (counterRef.current < repeatCount) {
  //         // Repeat the speech if needed
  //         startSpeech();
  //       } else {
  //         setIsPlaying(false);
  //         stopProgressTracking();  // Optional: stop any other related progress or cleanup
  //       }
  //     };

  //     speechQueueRef.current.push(speech);
  //   };

  //   // Trigger speech after user interaction
  //   startSpeech();
  // };

  const stopSpeech = () => {
    speechSynthesis.cancel();
    setIsPlaying(false);
    stopProgressTracking();
  };

  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
    // Update speech properties without interrupting playback
    if (speechSynthesis.speaking) {
      const currentSpeech =
        speechQueueRef.current[speechQueueRef.current.length - 1];
      currentSpeech.volume = newVolume; // Adjust volume dynamically
      // Restart the current speech with the updated volume
      if (newVolume < 0.5 || newVolume === 0) {
        speechSynthesis.cancel();
        stopSpeech();
      }
      speechSynthesis.cancel();
      speechSynthesis.speak(currentSpeech);
    }
  };

  const handleMute = (e) => {
    const newVolume = e;
    setVolume(newVolume);

    // Update speech properties without interrupting playback
    if (speechSynthesis.speaking) {
      const currentSpeech =
        speechQueueRef.current[speechQueueRef.current.length - 1];
      currentSpeech.volume = newVolume; // Adjust volume dynamically
      // Restart the current speech with the updated volume
      if (newVolume === 0) {
        stopSpeech();
        speechSynthesis.cancel();
      } else {
        speechSynthesis.cancel();
        speechSynthesis.speak(currentSpeech);
      }
    }
  };
  const handleRateChange = (e) => {
    const newRate = parseFloat(e.target.value);
    setRate(newRate);

    // Update speech properties without interrupting playback
    if (speechSynthesis.speaking) {
      const currentSpeech =
        speechQueueRef.current[speechQueueRef.current.length - 1];
      currentSpeech.rate = newRate; // Adjust rate dynamically
      // Restart the current speech with the updated rate
      speechSynthesis.cancel();
      speechSynthesis.speak(currentSpeech);
    }
  };

  const getVolumeIcon = () => {
    if (volume === 0) return <VolumeOffIcon />;
    if (volume < 0.5) return <VolumeDownIcon />;
    return <VolumeUpIcon />;
  };

  return (
    <Box
      sx={{
        bgcolor: "#fff",
        borderRadius: 1,
        p: 1.5,
        display: "flex",
        alignItems: "center",
        gap: 2,
        mb: 3,
      }}
    >
      <IconButton
        onClick={isPlaying ? stopSpeech : repeatParagraph}
        size="small"
        sx={{ color: "#333" }}
      >
        <i className={`fas fa-${isPlaying ? "pause" : "play"}`} />
      </IconButton>

      <Box
        sx={{
          flexGrow: 1,
          height: "4px",
          bgcolor: "#E9ECEF",
          borderRadius: "2px",
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            left: 0,
            top: 0,
            height: "100%",
            width: `${progress}%`,
            bgcolor: "#00BFA6",
            borderRadius: "2px",
          }}
        />
      </Box>
      <FormControl size="small">
        <Select
          value={rate}
          onChange={handleRateChange}
          sx={{
            color: "#666",
            minWidth: "auto",
            border: "1px solid black",
            padding: "2px !important",
          }}
          // renderValue={(value) => formatRate(value)}
        >
          <MenuItem value={0.5}>0.5x</MenuItem>
          <MenuItem value={0.88}>1x</MenuItem>
          <MenuItem value={1.5}>1.5x</MenuItem>
          <MenuItem value={2}>2x</MenuItem>
        </Select>
      </FormControl>

      <Box
        sx={{
          position: "relative",
          display: "flex",
          alignItems: "center",
        }}
        onMouseEnter={() => setIsHoveringVolume(true)}
        onMouseLeave={() => setIsHoveringVolume(false)}
      >
        <IconButton
          size="small"
          sx={{ color: "#333" }}
          onClick={() => {
            setVolume(volume === 0 ? 1 : 0);
            handleMute(volume === 0 ? 1 : 0);
          }}
        >
          {getVolumeIcon()}
        </IconButton>

        {isHoveringVolume && (
          <Box
            sx={{
              position: "absolute",
              bottom: "100%",
              left: "50%",
              transform: "translateX(-50%)",
              width: "24px",
              height: "100px",
              backgroundColor: "#fff",
              boxShadow: "0 0 10px rgba(0,0,0,0.1)",
              borderRadius: "4px",
              padding: "8px 0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <input
              type="range"
              min="0"
              max="1"
              step="0.01"
              value={volume}
              onChange={handleVolumeChange}
              style={{
                width: "80px",
                writingMode: "bt-lr",
                transform: "rotate(-90deg)",
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AudioPlayer;

// TextAreaCard.jsx
import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  IconButton,
  Button,
  Container,
  Modal,
  Select,
  MenuItem,
} from "@mui/material";
import { Switch } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { getApi } from "services/axiosInstance";
import { API_PATH } from "services/apipath";
import { postApi } from "services/axiosInstance";


const total = 9.0
function SummarizeTextAcedimic({ questionData }) {
  const timeLimit = 10;
  const wordLimit = { min: 200, max: 300 };

  const [studentText, setStudentText] = useState("");
  const [sampleText, setSampleText] = useState("");
  const [errors, setErrors] = useState([]);
  const [relevantKeywords, setRelevantKeywords] = useState([]);
  const [wordCount, setWordCount] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState(timeLimit * 60);
  const [isPaused, setIsPaused] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [scoreButton, setScoreButton] = useState(false);

  useEffect(() => {
    if (isPaused || timeRemaining <= 0) return;

    const timer = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(timer);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [isPaused, timeRemaining]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  const pauseTimer = () => {
    setIsPaused(true);
  };

  const handleTextChange = (e) => {
    const newText = e.target.value;
    setStudentText(newText);
    const words = newText.trim().split(/\s+/).filter(Boolean).length;
    setWordCount(words);
  };

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const Category = params.get("category");
  let id = location.pathname.split("/")[2];

  const navigate = useNavigate();

  const findIndex = () => {
    const questionIndex = questionData.questions?.findIndex(
      (question) => question.id === id
    );

    setCurrentIndex(questionIndex);
    setCurrentQuestion(questionIndex + 1);
    if (questionIndex === -1) {
    }
  };

  useEffect(() => {
    if (questionData) {
      findIndex();
    }
  }, [questionData]);

  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [submitedAns, setSubmitedAns] = useState(null);

  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [openAnalysisModal, setOpenAnalysisModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [scores, setScores] = useState({
    content: 0,
    pronunciation: 0,
    fluency: 0,
    overall: 0,
  });

  const [questionid, setQuestionId] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [questionDataApi, setQuestionDataApi] = useState([]);

  const getQuestionBtID = async (id) => {
    try {
      const res = await getApi(`${API_PATH.STUDENTS.GET_QUESTION_BY_ID}/${id}`);
      if (res.status === 200) {
        setQuestionDataApi(res.data.data);
        setSampleText(res.data.data.answer);
        setRelevantKeywords(res.data.data.keyWords);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (id) {
      getQuestionBtID(id);
    }
  }, []);

  const handleNext = async () => {
    if (currentQuestion === questionData.totalQuestions) {
      alert("Congratulations You have completed all questions!");
      return;
    } else {
      setCurrentQuestion(currentQuestion + 1);
      setCurrentIndex(currentIndex + 1);
      getQuestionBtID(questionData.questions[currentIndex + 1].id);
      setSubmitedAns(null);
      setSelectedAnswer(null);
    }
  };

  const handlePrev = async () => {
    if (currentQuestion > 1) {
      setCurrentQuestion(currentQuestion - 1);
      setCurrentIndex(currentIndex - 1);
      setQuestionId(questionData.questions[currentIndex - 1].id);
      getQuestionBtID(questionData.questions[currentIndex - 1].id);
      setSubmitedAns(null);
      setSelectedAnswer(null);
    }
  };

  const handleSubmit = async () => {
    const correctChoice = questionDataApi.choices.findIndex(
      (choice) => choice.is_correct
    );
    const choiceLabels = ["A", "B", "C", "D"];
    // Find the correct answer and its corresponding label
    const correctChoice1 = questionDataApi.choices.find(
      (choice) => choice.is_correct
    );
    const correctAnswerLabel =
      choiceLabels[questionDataApi.choices.indexOf(correctChoice1)];
    const selectedAnswerLabel = choiceLabels[selectedAnswer];

    if (selectedAnswer === correctChoice) {
      setSubmitedAns(true);
    } else {
      setSubmitedAns(false);
    }
    const payload = {
      questionId: id,
      answers: selectedAnswerLabel,
      correctAnswers: correctAnswerLabel,
    };
    try {
      const answer = await postApi(API_PATH.STUDENTS.SUBMIT_ANS, payload);
      if (answer.status === 200) {
      }
    } catch (error) {}
  };

  const [checked, setChecked] = useState(false);

  const handleToggle = (event) => {
    setChecked(event.target.checked);
  };

  // checking
  const checkRelevancy = (text) => {
    const textLower = text.toLowerCase();
    const foundKeywords = relevantKeywords.filter((keyword) =>
      textLower.includes(keyword.toLowerCase())
    );
    return {
      isRelevant: foundKeywords.length >= 2,
      keywordCount: foundKeywords.length,
      foundKeywords,
    };
  };

  const evaluateGrammarSpelling = async (text) => {
    try {
      setLoading(true);
      try {
        const response = await postApi(API_PATH.STUDENTS.CHECK_GRAMMAR, {
          text,
        });
        setLoading(false);

        const result = response.data.result;
        setErrors(result.edits);

        setWordCount(
          text.split(/\s+/).filter((word) => word.length > 0).length
        );
        setLoading(false);
        return result || [];
      } catch (error) {
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const [highlightedText, setHighlightedText] = useState(false);
  // Component to highlight mistakes based on API response
  const HighlightedText = ({ text, errors }) => {
    if (!text) return null;

    let displayText = text;
    const textParts = [];
    let lastIndex = 0;

    // Sort errors by offset to process them in order
    const sortedErrors = [...errors].sort((a, b) => a.start - b.start);

    sortedErrors.forEach((error) => {
      const { start, end, replace, err_cat } = error;
      const errorType = err_cat === "GRMR" ? "text-warning" : "text-danger";

      // Add text before error
      if (start > lastIndex) {
        textParts.push(
          <span key={`normal-${lastIndex}`} className="text-success">
            {text.slice(lastIndex, start)}
          </span>
        );
      }

      // Add error text with modification or deletion
      textParts.push(
        <span key={`error-${start}`} className={`p-1 ${errorType}`}>
          {replace || text.slice(start, end)}{" "}
          {/* If replace exists, show the replacement */}
        </span>
      );

      lastIndex = end;
    });

    // Add remaining text after the last error
    if (lastIndex < text.length) {
      textParts.push(
        <span key={`normal-end`} className="text-success">
          {text.slice(lastIndex)}
        </span>
      );
    }

    return <div className=" p-2 rounded highLightText">{textParts}</div>;
  };

  const evaluateText = async () => {
    setLoading(true);
    const apiResponse = await evaluateGrammarSpelling(studentText);
    const grammarErrors = apiResponse.edits;
    const relevancy = checkRelevancy(studentText);

    let newScores = {
      overall: 0,
      content: 0,
      form: 0,
      grammar: 0,
      vocabulary: 0,
      spelling: 0,
    };

    if (relevancy.isRelevant) {
      const baseScore = 90;
      const errorPenalty = grammarErrors.length * 5;

      // Calculate content score out of 100
      const contentScore = Math.max(0, baseScore - errorPenalty);
      const formScore = Math.max(0, baseScore - errorPenalty / 2);
      const grammarScore = Math.max(
        0,
        baseScore -
          grammarErrors.filter((e) => e.rule?.category === "GRMR").length * 10
      );
      const vocabularyScore = Math.max(
        0,
        (relevancy.keywordCount / relevantKeywords.length) * 100
      );
      const spellingScore = Math.max(
        0,
        baseScore -
          grammarErrors.filter((e) => e.rule?.category === "SPELL").length * 10
      );
      // Scale all scores to be out of 2
      const contentScoreOutOf2 = Math.round((contentScore / 100) * 2);
      const formScoreOutOf2 = Math.round((formScore / 100) * 1);
      const grammarScoreOutOf2 = Math.round((grammarScore / 100) * 2);
      const vocabularyScoreOutOf2 = Math.round((vocabularyScore / 100) * 2);
      const spellingScoreOutOf2 = Math.round((spellingScore / 100) * 2);

      newScores = {
        overall:
          parseFloat(contentScoreOutOf2) +
          parseFloat(formScoreOutOf2) +
          parseFloat(grammarScoreOutOf2) +
          parseFloat(vocabularyScoreOutOf2) +
          parseFloat(spellingScoreOutOf2),
        content: contentScoreOutOf2, // out of 2
        form: formScoreOutOf2, // out of 2
        grammar: grammarScoreOutOf2, // out of 2
        vocabulary: vocabularyScoreOutOf2, // out of 2
        spelling: spellingScoreOutOf2, // out of 2
      };
    }

    setScores(newScores);
    setErrors(grammarErrors);
    setWordCount(
      studentText.split(/\s+/).filter((word) => word.length > 0).length
    );
    const score = {
      total: total,
      overall: newScores.overall,
      content: newScores.content,
      form: newScores.form,
      grammar: newScores.grammar,
      spelling: newScores.spelling,
      linguistic: newScores.linguistic,
      text: studentText,
    };
    const payload = {
      questionId: id,
      stringAns: studentText,
      correctAnswers: sampleText,
      score: JSON.stringify(score),
    };
    setHighlightedText(true);
    try {
      const answer = await postApi(API_PATH.STUDENTS.SUBMIT_ANS, payload);
      if (answer.status) {
        setScoreButton(true);
        // setOpenAnalysisModal(true);
      }
    } catch (error) {}

    setLoading(false);
  };

  return (
    <Box sx={{ bgcolor: "#1A1F2E", minHeight: "100vh" }}>
      {/* Header */}
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bgcolor: "#1A1B2E",
          zIndex: 1000,
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              p: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid rgba(255,255,255,0.1)",
            }}
          >
            <IconButton sx={{ color: "#fff" }} onClick={() => navigate(-1)}>
              <i className="fas fa-arrow-left" />
            </IconButton>

            <Typography variant="h6" sx={{ color: "#fff", ml: "20px" }}>
              Summarize Text
            </Typography>

            <Box
              sx={{ display: "flex", alignItems: "center", gap: 1, ml: "auto" }}
            >
              <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                {currentQuestion}/{questionData.totalQuestions}
              </Typography>

              <IconButton sx={{ color: "#fff" }} onClick={() => handlePrev()}>
                <i className="fas fa-chevron-left" />
              </IconButton>
              <IconButton
                sx={{ color: "#fff" }}
                onClick={() => setOpenSearchModal(true)}
              >
                <i className="fas fa-search" />
              </IconButton>
              <IconButton sx={{ color: "#fff" }}>
                <i className="fas fa-rotate" />
              </IconButton>
              <IconButton sx={{ color: "#fff" }} onClick={() => handleNext()}>
                <i className="fas fa-chevron-right" />
              </IconButton>
            </Box>
          </Box>
        </Container>
      </Box>

      <Container maxWidth="lg" sx={{ mt: 9, pb: "80px" }}>
        {/* Instructions */}
        <Typography
          sx={{
            color: "#fff",
            fontStyle: "italic",
            mb: 2,
            fontSize: "17px",
          }}
        >
          Read the passage below and summarize it using one sentence. Type your
          response in the box at the bottom of the screen. You have 10 minutes
          to finish this task. Your response will be judged on the quality of
          your writing and on how well your response presents the key points in
          the passage.
        </Typography>

        {/* Question */}
        <Box
          sx={{
            bgcolor: "rgba(255,255,255,0.1)",
            p: 2,
            borderRadius: 1,
            mb: 3,
            width: "100%",
          }}
        >
          <Typography
            sx={{
              color: "#fff",
              wordWrap: "break-word",
              overflowWrap: "break-word",
              fontSize: "15px",
            }}
          >
            {questionDataApi.question_text}
          </Typography>
        </Box>

        {/* Answer Box */}
        <Box
          sx={{
            bgcolor: "#fff",
            borderRadius: 1,
            p: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography>Answer</Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography>words: {wordCount}</Typography>
            </Box>
          </Box>

          {!highlightedText && (
            <TextField
              fullWidth
              multiline
              rows={10}
              value={studentText}
              placeholder="Type the answer here"
              onChange={(e) => handleTextChange(e)}
              disabled={timeRemaining === 0}
              sx={{
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "#E8E9F3 !important",
                  width: "100% !important",
                  "&:hover": {
                    backgroundColor: "#E8E9F3 !important",
                  },
                  "& fieldset": {
                    borderColor: "#E0E0E0",
                  },
                },
                "& .MuiOutlinedInput-input": {
                  width: "100% !important",
                  backgroundColor: "#E8E9F3 !important",
                },
              }}
            />
          )}

          {highlightedText && (
            <HighlightedText text={studentText} errors={errors} />
          )}
          <Box
            display="flex"
            alignItems="center"
            sx={{
              padding: "10px 20px",
              borderRadius: "8px",
            }}
          >
            <Switch
              checked={checked}
              onChange={handleToggle}
              color="success" // Green color when toggled
            />
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                marginLeft: "8px",
              }}
            >
              Single Answer
            </Typography>
          </Box>
          {checked && (
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "400",
                marginTop: "16px",
                backgroundColor: "#f9f9f9",
                padding: "12px",
                borderRadius: "8px",
                border: "1px solid #e0e0e0",
              }}
            >
              {questionDataApi.answer}
            </Typography>
          )}
        </Box>

        {/* Timer and Submit Button */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 2,
            px: 2,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography sx={{ color: "#fff" }}>Time remaining</Typography>
            <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
              {formatTime(timeRemaining)}
            </Typography>
          </Box>
          {isSubmitting && !scoreButton && (
            <Button
              onClick={() => evaluateText()}
              sx={{
                mt: 2,
                bgcolor: "#00BFA6",
                color: "#fff !important",
                borderRadius: "8px",
                p: 2,
                "&:hover": { bgcolor: "#009e8b", color: "#fff" },
              }}
            >
              Analyze Answer
            </Button>
          )}

          {!isSubmitting && !scoreButton && (
            <Button
              onClick={() => {
                setIsSubmitting(true);
                pauseTimer();
              }}
              sx={{
                mt: 2,
                bgcolor: "#00BFA6",
                color: "#fff !important",
                borderRadius: "8px",
                p: 2,
                "&:hover": { bgcolor: "#009e8b", color: "#fff" },
              }}
            >
              Submit Answer
            </Button>
          )}

          {isSubmitting && scoreButton && (
            <Button
              onClick={() => {
                setOpenAnalysisModal(true);
              }}
              sx={{
                mt: 2,
                bgcolor: "#00BFA6",
                color: "#fff !important",
                borderRadius: "8px",
                p: 2,
                "&:hover": { bgcolor: "#009e8b", color: "#fff" },
              }}
            >
              View Score
            </Button>
          )}
        </Box>
      </Container>

      {/* Search Modal */}
      <Modal
        open={openSearchModal}
        onClose={() => setOpenSearchModal(false)}
        aria-labelledby="search-modal"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "#1A1B2E",
            borderRadius: "8px",
            p: 3,
            outline: "none",
            maxHeight: "80vh",
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 3,
              borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
              pb: 2,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Select
                value="All"
                size="small"
                sx={{
                  color: "#fff",
                  height: "32px",
                  minWidth: "100px",
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(255,255,255,0.3)",
                    borderRadius: "4px",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(255,255,255,0.5)",
                  },
                }}
              >
                <MenuItem value="All">All</MenuItem>
              </Select>
              <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                {/* {searchItems.length} Qs */}
              </Typography>
            </Box>
            <IconButton
              onClick={() => setOpenSearchModal(false)}
              sx={{
                color: "#fff",
                "&:hover": { bgcolor: "rgba(255,255,255,0.1)" },
              }}
            >
              <i className="fas fa-times" />
            </IconButton>
          </Box>

          {/* Search Items */}
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            {/* {searchItems.map((item, index) => (
              <Box
                key={item.id}
                sx={{
                  bgcolor: "#262842",
                  borderRadius: "8px",
                  p: 2,
                  cursor: "pointer",
                  "&:hover": {
                    bgcolor: "#2F315A",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                    {index + 1}. {item.id}
                  </Typography>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    {item.isNew && (
                      <Box
                        sx={{
                          bgcolor: "#FFC107",
                          px: 1,
                          borderRadius: "4px",
                          fontSize: "12px",
                          fontWeight: 500,
                          color: "#000",
                        }}
                      >
                        New
                      </Box>
                    )}
                    {item.isVIP && (
                      <Box
                        sx={{
                          bgcolor: "#FFC107",
                          px: 1,
                          borderRadius: "4px",
                          fontSize: "12px",
                          fontWeight: 500,
                          color: "#000",
                        }}
                      >
                        VIP
                      </Box>
                    )}
                  </Box>
                </Box>
                <Box sx={{ mt: 1 }}>
                  <Typography
                    sx={{
                      color: "rgba(255,255,255,0.6)",
                      fontSize: "12px",
                    }}
                  >
                    Attempted: {item.attempted}
                  </Typography>
                </Box>
              </Box>
            ))} */}
          </Box>
        </Box>
      </Modal>

      <Modal
        open={openAnalysisModal}
        onClose={() => setOpenAnalysisModal(false)}
        aria-labelledby="analysis-modal"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "auto",
            bgcolor: "#FFFFFF",
            borderRadius: "12px",
            p: 2,
            outline: "none",
            textAlign: "center",
          }}
        >
          {/* Green Checkmark Circle */}
          <Box
            sx={{
              width: "64px",
              height: "64px",
              bgcolor: "#00E676",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "0 auto 0px",
            }}
          >
            <i
              className="fas fa-check"
              style={{ color: "#FFFFFF", fontSize: "32px" }}
            />
          </Box>

          <Typography
            variant="h6"
            sx={{
              color: "#000000",
              fontSize: "18px",
              fontWeight: 500,
              mb: 0,
              textAlign: "center",
            }}
          >
            Score: {scores.overall}/{total}
          </Typography>

          {/* Score Categories */}
          <Box
            sx={{
              mb: 0,
              textAlign: "center",
              display: "inline-block",
              fontSize: "16px",
            }}
          >
            {[
              { label: "Content", score: scores.content, total: 2 },
              { label: "Form", score: scores.form, total: 1 },
              { label: "Grammar", score: scores.grammar, total: 2 },
              { label: "Vocabulary", score: scores.vocabulary, total: 2 },
              { label: "Spelling", score: scores.spelling, total: 2 },
            ].map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 1,
                  width: "450px",
                }}
              >
                <Typography
                  sx={{
                    flex: 1,
                    textAlign: "left",
                    color: "#666666",
                    width: "20%",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {item.label}
                </Typography>

                <Box
                  sx={{
                    flex: 2,
                    height: "6px",
                    bgcolor: "#F0F0F0",
                    borderRadius: "3px",
                    position: "relative",
                    mx: 2,
                    width: "60%",
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      left: 0,
                      top: 0,
                      height: "100%",
                      width: `${(item.score / item.total) * 100}%`,
                      bgcolor: "#00E676",
                      borderRadius: "3px",
                    }}
                  />
                </Box>

                <Typography
                  sx={{ color: "#666666", minWidth: "70px", width: "20%" }}
                >
                  {item.score}/{item.total}
                </Typography>
              </Box>
            ))}
          </Box>

          {/* OK Button */}
          <Button
            fullWidth
            sx={{
              bgcolor: "#00E676",
              color: "#FFFFFF",
              borderRadius: "25px",
              py: 1,
              textTransform: "none",
              fontSize: "16px",
              "&:hover": {
                bgcolor: "#00C853",
              },
            }}
            onClick={() => setOpenAnalysisModal(false)}
          >
            Ok
          </Button>
        </Box>
      </Modal>
    </Box>
  );
}

export default SummarizeTextAcedimic;

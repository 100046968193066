import React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import colors from "assets/theme/base/colors";

const { white } = colors;
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 4,
    p: 4,
};

const itemStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: "1px solid #ddd",
    borderRadius: "8px",
    padding: "8px 16px",
    marginBottom: "10px"
};

export default function StartFullTestModal({ open, setOpen, path }) {
    const navigate = useNavigate();

    const handleClose = () => setOpen(false);
    const handleStartTest = () => {
        navigate(path);
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box sx={style}>
                <Typography
                    id="modal-title"
                    variant="h6"
                    sx={{ textAlign: "center", mb: 3, fontWeight: "bold" }}
                >
                    Test Format
                </Typography>

                <Box>
                    <Box sx={itemStyle}>
                        <Typography>Introduction</Typography>
                        <Typography>1 min</Typography>
                    </Box>
                    <Box sx={itemStyle}>
                        <Typography>Speaking and Writing</Typography>
                        <Typography>54 - 67 minutes</Typography>
                    </Box>
                    <Box sx={itemStyle}>
                        <Typography>Reading</Typography>
                        <Typography>29 - 30 minutes</Typography>
                    </Box>
                    <Box sx={itemStyle}>
                        <Typography>Listening</Typography>
                        <Typography>30 - 43 minutes</Typography>
                    </Box>
                </Box>

                <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
                    <Button
                        variant="contained"
                        onClick={handleClose}
                        sx={{
                            bgcolor: "error.main",
                            "&:hover": { bgcolor: "error.dark" },
                            textTransform: "capitalize",
                            fontWeight: "bold",
                            borderRadius: "20px",
                            width: "48%",
                            color: white.main
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleStartTest}
                        sx={{
                            bgcolor: "success.main",
                            "&:hover": { bgcolor: "success.dark" },
                            textTransform: "capitalize",
                            fontWeight: "bold",
                            borderRadius: "20px",
                            width: "48%",
                            color: white.main
                        }}
                    >
                        Start Test
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
}

import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Container,
  Paper,
  MenuItem,
  Select,
  Modal,
  Button,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { getApi } from "services/axiosInstance";
import { API_PATH } from "services/apipath";
import AudioPlayer from "layouts/listening/AudioPlayer";
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import SendIcon from '@mui/icons-material/Send';


const SingleAnswerListeningMock = ({ questionData , onNext}) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const Category = params.get("category");
  let id = location.pathname.split("/")[2];

  const navigate = useNavigate();

  const findIndex = () => {
    const questionIndex = questionData.questions?.findIndex(
      (question) => question.id === id
    );

    setCurrentIndex(questionIndex);
    setCurrentQuestion(questionIndex + 1);
    if (questionIndex === -1) {
    }
  };

  useEffect(() => {
    if (questionData) {
      findIndex();
    }
  }, [questionData]);

  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [recordedAudio, setRecordedAudio] = useState(null);
  const [choices, setChoices] = useState([]);
  const [openAnalysisModal, setOpenAnalysisModal] = useState(false);
  const [scores, setScores] = useState({
    content: 0,
    pronunciation: 0,
    fluency: 0,
    overall: 0,
  });

  const [questionid, setQuestionId] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [questionDataApi, setQuestionDataApi] = useState([]);

  const getQuestionBtID = async (id) => {
    try {
      const res = await getApi(`${API_PATH.STUDENTS.GET_QUESTION_BY_ID}/${id}`);
      if (res.status === 200) {
        setQuestionDataApi(res.data.data);
      }
    } catch (error) { }
  };

  useEffect(() => {
    if (id) {
      getQuestionBtID(id);
    }
  }, []);

  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [submitedAns, setSubmitedAns] = useState(null);

  const handleAnswerSelect = (index) => {
    setSelectedAnswer(index);
  };

  const handleSubmit = async () => {
    const correctChoice = questionDataApi.choices.findIndex(
      (choice) => choice.is_correct
    );
    const choiceLabels = ["A", "B", "C", "D"];
    // Find the correct answer and its corresponding label
    const correctChoice1 = questionDataApi.choices.find(
      (choice) => choice.is_correct
    );
    const correctAnswerLabel =
      choiceLabels[questionDataApi.choices.indexOf(correctChoice1)];
    const selectedAnswerLabel = choiceLabels[selectedAnswer];

    if (selectedAnswer === correctChoice) {
      setSubmitedAns(true);
    } else {
      setSubmitedAns(false);
    }
    const payload = {
      questionId: id,
      answers: selectedAnswerLabel,
      correctAnswers: correctAnswerLabel,
    };
  };

  useEffect(() => {
    return () => {
      if (recordedAudio?.url) {
        URL.revokeObjectURL(recordedAudio.url);
      }
    };
  }, []);

  const handleRecordingComplete = (recording) => {
    setRecordedAudio(recording);

    if (recording.scores) {
      setScores({
        content: recording.scores.content,
        pronunciation: recording.scores.pronunciation,
        fluency: recording.scores.fluency,
        overall: Math.round(
          (recording.scores.content +
            recording.scores.pronunciation +
            recording.scores.fluency) /
          3
        ),
      });
    }
  };

  const handleNext = async () => {
    if (currentQuestion === questionData.totalQuestions) {
      alert("Congratulations You have completed all questions!");
      return;
    } else {
      setCurrentQuestion(currentQuestion + 1);
      setCurrentIndex(currentIndex + 1);
      getQuestionBtID(questionData.questions[currentIndex + 1].id);
      setSubmitedAns(null);
      setSelectedAnswer(null);
    }
  };

  const handlePrev = async () => {
    if (currentQuestion > 1) {
      setCurrentQuestion(currentQuestion - 1);
      setCurrentIndex(currentIndex - 1);
      setQuestionId(questionData.questions[currentIndex - 1].id);
      getQuestionBtID(questionData.questions[currentIndex - 1].id);
      setSubmitedAns(null);
      setSelectedAnswer(null);
    }
  };

  return (
    <Box sx={{ bgcolor: "#white" }}>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bgcolor: "white",
          zIndex: 100000,
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              p: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              // borderBottom: "5px solid #80CBC4",
              verticalAlign: "middle",
            }}
          >
            {/* <IconButton sx={{ color: "#fff" }} onClick={() => navigate(-1)}>
              <i className="fas fa-arrow-left" />
            </IconButton> */}

            <Typography variant="h6" sx={{ color: "black", ml: "20px" }}>
              {/* {currentMonthAndDate} #123 */}
            </Typography>

            <Box
              sx={{ display: "flex", alignItems: "center", gap: 1, ml: "auto" }}
            >
              <Typography sx={{ color: "black", fontSize: "15px", marginRight: "16px" }}>
                {/* {currentQuestion}/{questionData.totalQuestions} */}
                1/1
              </Typography>

              <Box sx={{ color: "#000000", border: "1px solid grey", paddingX: "5px", paddingY: "3px", borderRadius: "6px", display: "flex", justifyContent: "space-evenly", verticalAlign: "middle !important", }}>
                <AccessAlarmsIcon sx={{ marginRight: "5px", fontSize: "16px !important", marginTop: "4px" }} />

                <Typography sx={{ fontSize: "15px" }}>
                  {/* {formatTime()} */}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>

      <Container maxWidth="lg" sx={{ mt: 9 }}>
        {/* Added Instructions Text */}
        <Typography
          sx={{
            color: "black",
            fontStyle: "italic",
            mb: 2,
            fontSize: "15px",
          }}
        >
          Listen to the recording and answer the multiple-choice question by
          selecting the correct response. Only one response is correct.
        </Typography>

        {/* Instructions */}
        <Paper sx={{ p: 1, my: 2, color: "#fff", backgroundColor: "#1A1A2E" }}>
          <Typography>{questionDataApi.question_text}</Typography>
        </Paper>

        {/* <Aution Text /> */}
        {/* <AudioPlayer
          audioText={JSON.stringify(questionDataApi.audio_text)}
          delay={3}
        /> */}

        {/* Answer Options */}
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {questionDataApi.choices?.map((choice, index) => (
            <Box
              key={index}
              onClick={() => { submitedAns === null && handleAnswerSelect(index) }}
              sx={{
                bgcolor:
                  submitedAns === null // Before submission (no color change yet)
                    ? selectedAnswer === index // If this option is selected
                      ? "#00BFA6" // Keep selected option highlighted (light blue)
                      : "rgba(52, 58, 64, 0.8)" // Default neutral color for non-selected answers
                    : // After submission, update colors based on correctness
                    selectedAnswer === index // If this option is selected by the user
                      ? submitedAns && choice.is_correct
                        ? "green" // Correct answer: green
                        : "red" // Wrong answer: red
                      : choice.is_correct // Highlight correct answer
                        ? "green" // Correct answer highlighted in green
                        : "rgba(52, 58, 64, 0.8)", // Neutral color for other unselected answers
                p: 2.5,
                borderRadius: "8px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                transition: "all 0.2s ease",
                "&:hover": {
                  bgcolor:
                    submitedAns === null
                      ? selectedAnswer === index // If option is selected, it will remain highlighted on hover
                        ? "#00BFA6" // Keep selected option hover color same
                        : "rgba(52, 58, 64, 0.9)" // Darken other options on hover
                      : selectedAnswer === index // If this option is selected by the user
                        ? submitedAns && choice.is_correct
                          ? "green" // Correct answer: green
                          : "red" // Wrong answer: red
                        : choice.is_correct // Highlight correct answer
                          ? "green" // Correct answer highlighted in green
                          : "rgba(52, 58, 64, 0.8)", // Default hover color after submission (for non-selected)
                },
              }}
            >
              <Typography sx={{ fontSize: "14px", color: "#fff" }}>
                {choice.choice_text}
              </Typography>
            </Box>
          ))}
        </Box>
      </Container>

      <Container>
        <Box
          sx={{
            // p: 2,
            my: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            verticalAlign: "middle",
          }}
        >
          <Button variant="outlined" sx={{ color: "black !important" }}>Save & Exit</Button>

          <Box
            sx={{ display: "flex", alignItems: "center", gap: 1, ml: "auto" }}
          >
            <Button variant="contained" sx={{ color: "white !important" }} endIcon={<SendIcon />} onClick={onNext}>
              Next
            </Button>
          </Box>
        </Box>
      </Container>


    </Box>
  );
};

export default SingleAnswerListeningMock;

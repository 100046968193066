import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Container,
  Modal,
  Button,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import AudioComponent from "components/Recorder/AudioComponent";
// import RepeatParagraphAloud from "../../../../components/AudioPlayer/AudioPlayer";
import { getApi } from "services/axiosInstance";
import { API_PATH } from "services/apipath";
import { postApi } from "services/axiosInstance";
import PteSpeakingPractice from "./PteSpeakingPractice";
import RepeatParagraphAloud from "components/AudioPlayer/AudioPlayer";
import SendIcon from '@mui/icons-material/Send';

const ShortAnswerMock = ({ questionData, onNext }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const SubCategory = params.get("subcategory");
  let id = location.pathname.split("/")[2];

  const navigate = useNavigate();

  const findIndex = () => {
    const questionIndex = questionData.questions?.findIndex(
      (question) => question.id === id
    );

    setCurrentIndex(questionIndex);
    setCurrentQuestion(questionIndex + 1);
    if (questionIndex === -1) {
    } else {
    }
  };

  useEffect(() => {
    if (questionData) {
      findIndex();
    }
  }, [questionData]);

  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [recordedAudio, setRecordedAudio] = useState(null);
  const [openAnalysisModal, setOpenAnalysisModal] = useState(false);
  const [scores, setScores] = useState({
    content: "",
    pronunciation: 0,
    fluency: 0,
    overall: 0,
    vocabulary: 0,
    grammar: 0,
  });

  const [questionid, setQuestionId] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [questionDataApi, setQuestionDataApi] = useState([]);

  const getQuestionBtID = async (id) => {
    try {
      const res = await getApi(`${API_PATH.STUDENTS.GET_QUESTION_BY_ID}/${id}`);
      if (res.status === 200) {
        setQuestionDataApi(res.data.data);
        setRecordedAudio(null)
        setStartRecording(false);
      }
    } catch (error) { }
  };

  useEffect(() => {
    if (id && questionid === undefined) {
      getQuestionBtID(id);
    }
  }, []);

  useEffect(() => {
    return () => {
      if (recordedAudio?.url) {
        URL.revokeObjectURL(recordedAudio.url);
      }
    };
  }, []);

  const handleRecordingComplete = async (recording) => {
    setRecordedAudio(recording);

    const formData = new FormData();
    formData.append("questionId", id);

    formData.append("ansfile", recording.blob);
    const ans_sub = await postApi(`${API_PATH.STUDENTS.SUBMIT_ANS}`, formData);
    if (ans_sub.status === 201) {
      if (ans_sub?.data?.data?.score?.text !== "") {
        setScores({
          content: ans_sub?.data?.data?.score?.text || "",
          pronunciation: ans_sub?.data?.data?.score?.pronunciation || 0,
          fluency: ans_sub?.data?.data?.score?.fluency || 0,
          overall: ans_sub?.data?.data?.score?.overall || 0,
          vocabulary: ans_sub?.data?.data?.score?.vocabulary || 0,
          grammar: ans_sub?.data?.data?.score?.grammar || 0,
        });
      }
    }
  };

  const handleNext = async () => {
    if (currentQuestion === questionData.totalQuestions) {
      alert("Congratulations You have completed all questions!");
      return;
    } else {
      setCurrentQuestion(currentQuestion + 1);
      setCurrentIndex(currentIndex + 1);
      getQuestionBtID(questionData.questions[currentIndex + 1].id);
    }
  };

  const handlePrev = async () => {
    if (currentQuestion > 1) {
      setCurrentQuestion(currentQuestion - 1);
      setCurrentIndex(currentIndex - 1);
      setQuestionId(questionData.questions[currentIndex - 1].id);
      getQuestionBtID(questionData.questions[currentIndex - 1].id);
    }
  };

  const [timer, setTimer] = useState(3);
  const [startRecording, setStartRecording] = useState(() => {
    // Initialize the startRecording flag from localStorage, or default to false
    const savedRecording = localStorage.getItem("startRecording");
    return savedRecording ? JSON.parse(savedRecording) : false;
  });


  const handleAudioComplete = () => {
    console.log(timer, "Audio Complete");
    setStartRecording(true);
    setTimer(15);
  };
  return (
    <Box sx={{ bgcolor: "white", p: 2 }}>
      <Container
        maxWidth="lg"
        sx={{
          pt: "70px",
          // p: 2,
          // minHeight: "95vh",
          position: "relative",
          width: "100% !important",
        }}
      >

        <Typography
          sx={{
            // mt: 8,
            // mb: 2,
            fontSize: "15px",
            fontStyle: "italic",
            color: "black"
          }}
        >
          You will hear a question. Please give a simple and short answer. Often
          just one or a few words is enough.
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingY: "35px"
          }}
        >
          {/* <RepeatParagraphAloud
            onAudioComplete={handleAudioComplete}
            audioText={JSON.stringify(questionDataApi.audio_text)}
            delay={3}
          /> */}

          <PteSpeakingPractice
            originalText={questionDataApi.answer}
            id={questionDataApi._id}
            SubCategory={SubCategory}
            timer={startRecording ? timer : 3}
            startRecording={startRecording}
          />

        </Box>
        <Typography sx={{ color: "black !important", fontSize: "16px" }}>{questionDataApi.question_text} Countries count vehicles and power plants when calculating their annual carbon emissions, but they do not include bush fires, likely because they are considered uncontrollable natural disasters.</Typography>

      </Container>

      <Container>
        <Box
          sx={{
            // p: 2,
            mt: 3,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            verticalAlign: "middle",
          }}
        >

          <Button variant="outlined" sx={{ color: "black !important" }}>Save & Exit</Button>

          <Box
            sx={{ display: "flex", alignItems: "center", gap: 1, ml: "auto" }}
          >
            <Button variant="contained" sx={{ color: "white !important" }} endIcon={<SendIcon />} onClick={onNext}>
              Next
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default ShortAnswerMock;

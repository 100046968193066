import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Container,
  Modal,
  Button,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import AudioComponent from "components/Recorder/AudioComponent";
import RepeatParagraphAloud from "../../../../components/AudioPlayer/AudioPlayer";
import { getApi } from "services/axiosInstance";
import { API_PATH } from "services/apipath";
import { postApi } from "services/axiosInstance";
import PteSpeakingPractice from "./PteSpeakingPractice";

const RepeatSentenceAnswers = ({ questionData }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const SubCategory = params.get("subcategory");
  let id = location.pathname.split("/")[2];

  const navigate = useNavigate();

  const findIndex = () => {
    const questionIndex = questionData.questions?.findIndex(
      (question) => question.id === id
    );

    setCurrentIndex(questionIndex);
    setCurrentQuestion(questionIndex + 1);
    if (questionIndex === -1) {
    }
  };

  useEffect(() => {
    if (questionData) {
      findIndex();
    }
  }, [questionData]);

  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [recordedAudio, setRecordedAudio] = useState(null);
  const [openAnalysisModal, setOpenAnalysisModal] = useState(false);
  const [scores, setScores] = useState({
    content: "",
    pronunciation: 0,
    fluency: 0,
    overall: 0,
    vocabulary: 0,
    grammar: 0,
  });

  const [questionid, setQuestionId] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [questionDataApi, setQuestionDataApi] = useState([]);

  const getQuestionBtID = async (id) => {
    try {
      const res = await getApi(`${API_PATH.STUDENTS.GET_QUESTION_BY_ID}/${id}`);
      if (res.status === 200) {
        setQuestionDataApi(res.data.data);
        setRecordedAudio(null)
        setStartRecording(false);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (id && questionid === undefined) {
      getQuestionBtID(id);
    }
  }, []);

  useEffect(() => {
    return () => {
      if (recordedAudio?.url) {
        URL.revokeObjectURL(recordedAudio.url);
      }
    };
  }, []);

  const handleRecordingComplete = async (recording) => {
    setRecordedAudio(recording);

    const formData = new FormData();
    formData.append("questionId", id);

    formData.append("ansfile", recording.blob);
    const ans_sub = await postApi(`${API_PATH.STUDENTS.SUBMIT_ANS}`, formData);
    if (ans_sub.status === 201) {
      if (ans_sub?.data?.data?.score?.text !== "") {
        setScores({
          content: ans_sub?.data?.data?.score?.text || "",
          pronunciation: ans_sub?.data?.data?.score?.pronunciation || 0,
          fluency: ans_sub?.data?.data?.score?.fluency || 0,
          overall: ans_sub?.data?.data?.score?.overall || 0,
          vocabulary: ans_sub?.data?.data?.score?.vocabulary || 0,
          grammar: ans_sub?.data?.data?.score?.grammar || 0,
        });
      }
    }
  };

  const handleNext = async () => {
    if (currentQuestion === questionData.totalQuestions) {
      alert("Congratulations You have completed all questions!");
      return;
    } else {
      setCurrentQuestion(currentQuestion + 1);
      setCurrentIndex(currentIndex + 1);
      getQuestionBtID(questionData.questions[currentIndex + 1].id);
    }
  };

  const handlePrev = async () => {
    if (currentQuestion > 1) {
      setCurrentQuestion(currentQuestion - 1);
      setCurrentIndex(currentIndex - 1);
      setQuestionId(questionData.questions[currentIndex - 1].id);
      getQuestionBtID(questionData.questions[currentIndex - 1].id);
    }
  };

  const [timer, setTimer] = useState(3);
  const [startRecording, setStartRecording] = useState(() => {
    // Initialize the startRecording flag from localStorage, or default to false
    const savedRecording = localStorage.getItem("startRecording");
    return savedRecording ? JSON.parse(savedRecording) : false;
  });

  // useEffect(() => {
  //   // Simulate a delay (5 seconds) after a button click
  //   const timer = setTimeout(() => {
  //     setTimer(15)
  //     console.log("timer")
  //   }, 5000); // 5000ms = 5 seconds

  //   // Cleanup function to clear the timeout if the component is unmounted
  //   return () => clearTimeout(timer);
  // }, []);

  const handleAudioComplete = () => {
    console.log(timer, "Audio Complete");
    setStartRecording(true);
    setTimer(15);
  };

  return (
    <Box sx={{ bgcolor: "#1a1a2e", minHeight: "100vh", p: 2 }}>
      {/* Fixed Header */}
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bgcolor: "#1A1B2E",
          zIndex: 1000,
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              p: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid rgba(255,255,255,0.1)",
            }}
          >
            <IconButton sx={{ color: "#fff" }} onClick={() => navigate(-1)}>
              <i className="fas fa-arrow-left" />
            </IconButton>

            <Typography variant="h6" sx={{ color: "#fff", ml: "20px" }}>
              Repeat Sentence
            </Typography>

            <Box
              sx={{ display: "flex", alignItems: "center", gap: 1, ml: "auto" }}
            >
              <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                {currentQuestion}/{questionData.totalQuestions}
              </Typography>
              <IconButton sx={{ color: "#fff" }} onClick={() => handlePrev()}>
                <i className="fas fa-chevron-left" />
              </IconButton>
              <IconButton
                sx={{ color: "#fff" }}
                onClick={() => setOpenSearchModal(true)}
              >
                <i className="fas fa-search" />
              </IconButton>
              <IconButton sx={{ color: "#fff" }}>
                <i className="fas fa-rotate" />
              </IconButton>
              <IconButton sx={{ color: "#fff" }} onClick={() => handleNext()}>
                <i className="fas fa-chevron-right" />
              </IconButton>
            </Box>
          </Box>
        </Container>
      </Box>

      <Container
        maxWidth="lg"
        sx={{
          pt: "80px",
          p: 2,
          height: "95vh",
          position :'relative',
          width : '100%'
        }}
      >
        {/* Instructions Text */}
        <Typography
          sx={{
            color: "#fff",
            mt: 8,
            mb: 2,
            fontSize: "17px",
            fontStyle: "italic",
          }}
        >
          You will hear a speech. After listening to the speech, in 15 seconds,
          please speak into the microphone and retell what you have just heard
          from the speech in your own words. You will have 40 seconds to give
          your response.
        </Typography>

        {/* <RepeatParagraphAloud /> */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <RepeatParagraphAloud
            onAudioComplete={handleAudioComplete}
            audioText={JSON.stringify(questionDataApi.audio_text)}
            delay={3}
          />

          <PteSpeakingPractice
            originalText={questionDataApi.audio_text}
            id={questionDataApi._id}
            SubCategory={SubCategory}
            timer={startRecording ? timer: 3}
            startRecording={startRecording}
          />

          {recordedAudio && (
            <Box sx={{ mt: 3 }}>
              <AudioComponent
                src={recordedAudio.url}
                type={recordedAudio.mimeType}
              />
              <Button
                variant="contained"
                sx={{
                  mt: 2,
                  bgcolor: "#4caf50",
                  color: "#fff",
                  "&:hover": {
                    bgcolor: "#357a38",
                  },
                  "&.MuiButton-contained": {
                    backgroundColor: "#4caf50",
                    "&:hover": {
                      backgroundColor: "#357a38",
                    },
                  },
                }}
                onClick={() => setOpenAnalysisModal(true)}
              >
                Analyze Recording
              </Button>
            </Box>
          )}
        </Box>
        {/* </Paper> */}

        {/* Analysis Modal */}
        <Modal
          open={openAnalysisModal}
          onClose={() => setOpenAnalysisModal(false)}
          aria-labelledby="analysis-modal"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "auto",
              bgcolor: "#FFFFFF",
              borderRadius: "12px",
              p: 3,
              outline: "none",
              textAlign: "center",
            }}
          >
            {/* Green Checkmark Circle */}

            <Box
              sx={{
                width: "64px",
                height: "64px",
                bgcolor: "#00E676",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "0 auto 20px",
              }}
            >
              <i
                className="fas fa-check"
                style={{ color: "#FFFFFF", fontSize: "32px" }}
              />
            </Box>

            <Typography
              variant="h6"
              sx={{
                color: "#000000",
                fontSize: "24px",
                fontWeight: 500,
                mb: 3,
              }}
            >
              Score:{scores.overall}/90.0
            </Typography>
            {/* Score Categories */}

            <Box sx={{ mb: 3 }}>
              {[
                { label: "Grammar", score: scores.grammar, total: 90 },
                { label: "Fluency", score: scores.fluency, total: 90 },
                {
                  label: "Pronunciation",
                  score: scores.pronunciation,
                  total: 90,
                },
                {
                  label: "Vocabulary",
                  score: scores.vocabulary,
                  total: 90,
                },
              ].map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: 2,
                    width: "450px",
                  }}
                >
                  <Typography
                    sx={{
                      flex: 1,
                      textAlign: "left",
                      color: "#666666",
                      width: "20%",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    {item.label}
                  </Typography>

                  <Box
                    sx={{
                      flex: 2,
                      height: "6px",
                      bgcolor: "#F0F0F0",
                      borderRadius: "3px",
                      position: "relative",
                      mx: 2,
                      width: "60%",
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        height: "100%",
                        paddingRight: `${(item.score / item.total) * 100}%`,
                        bgcolor: "#00E676",
                        borderRadius: "3px",
                      }}
                    />
                  </Box>

                  <Typography
                    sx={{ color: "#666666", minWidth: "70px", width: "20%" }}
                  >
                    {item.score}/{item.total}
                  </Typography>
                </Box>
              ))}
            </Box>
            {/* Transcription Display (Optional) */}
            {scores.content && (
              <Box sx={{ mb: 3, textAlign: "left" }}>
                <Typography sx={{ color: "#666666", fontWeight: 500, mb: 1 }}>
                  Your Speech:
                </Typography>

                <Typography sx={{ color: "#666666", fontSize: "14px" }}>
                  {scores.content}
                </Typography>
              </Box>
            )}
            {/* OK Button */}

            <Button
              fullWidth
              sx={{
                bgcolor: "#00E676",
                color: "#FFFFFF",
                borderRadius: "25px",
                py: 1.5,
                textTransform: "none",
                fontSize: "16px",
                "&:hover": {
                  bgcolor: "#00C853",
                },
              }}
              onClick={() => setOpenAnalysisModal(false)}
            >
              Ok
            </Button>
          </Box>
        </Modal>
      </Container>

      {/* Search Modal */}
    </Box>
  );
};

export default RepeatSentenceAnswers;

import React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import DoneIcon from "@mui/icons-material/Done";
import colors from "assets/theme/base/colors";

const { error, success, primary } = colors;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 420,
  bgcolor: "background.paper",
  borderRadius: 4,
};

export default function StartTestModal({ startModalOpen, setStartModalOpen, path }) {
  const navigate = useNavigate()

  const handleClose = () => setStartModalOpen(false);
  const handleClick = (path) => {
    navigate(path)
  }
  return (
    <div>
      <Modal
        open={startModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <Box
              sx={{
                flex: 1,
                p: 2.5,
                pt: 5,
                position: "relative",
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                gap: 2,
                backgroundColor: "transparent",
              }}
            >
              <Box
               sx={{
                position: "absolute",
                top: "-10%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                p: 3,
                bgcolor: "background.paper",
                borderRadius: "50%",
                borderWidth: 8,
                borderColor: "#fff",
                borderStyle: "solid",
                // bgcolor: success.main,
                backgroundColor:"#3A416F !important"
              }}
              >
                <DoneIcon fontSize="large" />
              </Box>
              <Typography sx={{ fontSize: "1.5rem" }}>Start Test?</Typography>
              <Box sx={{ textAlign: "center", display: "flex", gap: 2 }}>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  size="small"
                  sx={{
                    bgcolor: error.main,
                    "&:hover": { bgcolor: error.focus },
                    fontSize: "0.9rem",
                    minWidth: "180px",
                    fontWeight: "500",
                    color: "#fff",
                    textTransform: "capitalize",
                    borderRadius: 5,
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    bgcolor: "#3A416F",
                    "&:hover": { bgcolor: success.focus },
                    fontSize: "0.9rem",
                    minWidth: "180px",
                    fontWeight: "500",
                    color: "#fff",
                    textTransform: "capitalize",
                    borderRadius: 5,
                  }}
                  onClick={() => handleClick(path)}
                >
                  Start
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

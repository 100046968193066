export const API_PATH = {
    STUDENTS: {
      LOGIN: "v2/student/login",
      GET_STUDENT_PROFILE: "v2/student/getme",
      GET_QUESTIONS_SPEAKING_READALOUD: "v2/student/get-questions",
      GET_QUESTIONS_IDS_BY_CATEGORY : 'v2/student/get-questionsID',
      GET_QUESTION_BY_ID : 'v2/student/get-questions-by-id',
      SUBMIT_ANS : 'v2/student/save-attempts',
      CHECK_GRAMMAR : 'v2/student/check-grammar',
      GET_ATTEMPT_PRACTICE : "v2/student/attempt-history"
    },
  };
  
export const processQuestionText = (text) => {
  // Check if text contains dashQ
  if (text.includes("dashQ")) {
    return {
      parts: text.split("dashQ"),
      totalBlanks: text.split("dashQ").length - 1,
    };
  }
  // Check if text contains ____
  if (text.includes("____")) {
    return {
      parts: text.split("____"),
      totalBlanks: text.split("____").length - 1,
    };
  }
  return {
    parts: [text],
    totalBlanks: 0,
  };
};
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

//  Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

//  Dashboard React examples
// import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import PageLayout from "examples/LayoutContainers/PageLayout"; 

function CoverLayout({
  color,
  header,
  title,
  description,
  image,
  top,
  children,
}) {
  return (
    <PageLayout  
       
    >
      {/* <DefaultNavbar
        action={{
          type: "external",
          route: "/",
          label: "free download",
          color: "dark",
        }}
      /> */}

      <Grid
        container
        justifyContent="left" 
        sx={{
          minHeight: "70vh",
          marginLeft: "60px",
          marginY: 0,
          marginRight: 0,
        }}
      >
        <Grid item xs={11} sm={8} md={5} xl={3}>
          <SoftBox mt={top}>
            <SoftBox px={3}>
              {!header ? (
                <>
                  <SoftBox mb={1}>
                    <SoftTypography
                      variant="h2"
                      fontWeight="bold"
                      color={color}
                      textGradient
                    >
                      {title}
                    </SoftTypography>
                  </SoftBox>
                  <SoftTypography
                    variant="body2"
                    fontWeight="regular"
                    color="black"
                  >
                    {description}
                  </SoftTypography>
                </>
              ) : (
                header
              )}
            </SoftBox>
            <SoftBox p={3}>{children}</SoftBox>
          </SoftBox>
        </Grid>

        <Grid item xs={12} md={5}>
          <SoftBox
            height="100%"
            display={{ xs: "none", lg: "block" }}
            position="relative"
            right={{ md: "-12rem", xl: "-16rem" }}
            mr={-16}
            sx={{
              // transform: "skewX(0deg)",
              overflow: "hidden",
              borderBottomLeftRadius: ({ borders: { borderRadius } }) =>
                borderRadius.lg,
            }}
          >
            <SoftBox
              ml={-8}
              height="100%"
              sx={{
                // backgroundImage: `url(${image})`,
                backgroundSize: "cover",
                // transform: "skewX(0deg)",
              }}
            />
          </SoftBox>
        </Grid>
      </Grid>
    </PageLayout>
  );
}

// Setting default values for the props of CoverLayout
CoverLayout.defaultProps = {
  header: "",
  title: "",
  description: "",
  color: "info",
  top: 16,
};

// Typechecking props for the CoverLayout
CoverLayout.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  header: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string.isRequired,
  top: PropTypes.number,
  children: PropTypes.node.isRequired,
};

export default CoverLayout;

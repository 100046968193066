import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Button,
  Box,
  Typography,
  Modal,
  IconButton,
  Switch,
} from "@mui/material";
import { ReactMic } from "react-mic";
import AudioComponent from "components/Recorder/AudioComponent";
import { Mic, Stop } from "@mui/icons-material";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { getApi, postApi } from "services/axiosInstance";
import { API_PATH } from "services/apipath";

const total = 90.0;

const PteSpeakingPractice = ({
  originalText = "",
  id,
  SubCategory,
  timer,
  startRecording,
}) => {
  const initalPrepTimer = timer;

  const [isListening, setIsListening] = useState(false);
  const [isRecordingStopped, setIsRecordingStopped] = useState(false);
  const [spokenWords, setSpokenWords] = useState([]);
  const [wordStatus, setWordStatus] = useState({});
  const [openAnalysisModal, setOpenAnalysisModal] = useState(false);
  const [recordedAudio, setRecordedAudio] = useState(null);
  const [preparationTimer, setPreparationTimer] = useState(initalPrepTimer);
  const [recordingTimer, setRecordingTimer] = useState(40);
  const [timerPhase, setTimerPhase] = useState("pre-preparation"); // 'preparation' | 'recording' | 'completed'
  const [timerVisible, setTimerVisible] = useState(true);
  const [scores, setScores] = useState({
    pronunciation: 0,
    fluency: 0,
    content: 0,
    totalScore: 0,
  });

  const [color, setColor] = useState(false);
  const [checked, setChecked] = useState(
    SubCategory === "Repeat Sentence" ||
      SubCategory === "Retell Lecture" ||
      SubCategory === "Short Answer"
      ? false
      : true
  );

  useEffect(() => {
    setPreparationTimer(timer);
  }, [timer]);

  console.log(timer, preparationTimer
  )

  useEffect(() => {
    setIsListening(false);
    setIsRecordingStopped(false);
    setTimerPhase("pre-preparation");
    setChecked(false)
  }, [id]);

  const originalWordsRef = useRef(originalText.split(/(\s+)/));
  const recognitionRef = useRef(null);
  const recordingTimeoutRef = useRef(null);

  const createBeep = () => {
    try {
      const audioContext = new AudioContext();
      const oscillator = audioContext.createOscillator();
      const gainNode = audioContext.createGain();

      // Connect the nodes
      oscillator.connect(gainNode);
      gainNode.connect(audioContext.destination);

      // Configure oscillator
      oscillator.type = "sine";
      oscillator.frequency.setValueAtTime(800, audioContext.currentTime); // Frequency in hertz

      // Configure gain (volume)
      gainNode.gain.setValueAtTime(0.3, audioContext.currentTime); // Start at 10% volume

      // Start and stop the beep
      oscillator.start(audioContext.currentTime);
      oscillator.stop(audioContext.currentTime + 0.1); // Beep duration: 150ms

      // Cleanup
      setTimeout(() => {
        gainNode.disconnect();
        oscillator.disconnect();
      }, 200);
    } catch (error) {
      console.error("Error creating beep:", error);
    }
  };

  useEffect(() => {
    if (startRecording === true) {
      createBeep();
      setTimerPhase("preparation");
      setPreparationTimer(15)
      setTimeout(() => {
        startListening();
      }, 15000);
    }
  }, [timer, startRecording]);

  useEffect(() => {
    if (timerPhase !== "pre-preparation" || preparationTimer <= 0) return;

    const intervalId = setInterval(() => {
      setPreparationTimer((prev) => {
        if (prev <= 1) {
          setTimerPhase("preparation");
          setPreparationTimer(15)
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [preparationTimer, timerPhase]);

  // Timer logic for preparation phase
  useEffect(() => {
    if (timerPhase !== "preparation" || preparationTimer <= 0)
      return;

    const intervalId = setInterval(() => {
      setPreparationTimer((prev) => {
        if (prev === 2) {
          createBeep();
        }
        if (prev <= 1) {
          setTimerPhase("recording");
          startListening();
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [preparationTimer, timerPhase]);



  // Timer logic for recording phase
  useEffect(() => {
    if (timerPhase !== "recording" || !isListening) return;

    const intervalId = setInterval(() => {
      setRecordingTimer((prev) => {
        if (prev <= 1) {
          stopListening();
          setTimerPhase("completed");
          setTimerVisible(false); // Hide timer when recording ends
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [recordingTimer, timerPhase, isListening]);

  // Initialize Speech Recognition
  useEffect(() => {
    if ("webkitSpeechRecognition" in window) {
      recognitionRef.current = new window.webkitSpeechRecognition();
      recognitionRef.current.continuous = true;
      recognitionRef.current.interimResults = true;
      recognitionRef.current.lang = "en-US";

      recognitionRef.current.onresult = (event) => {
        console.log(event, "result");
        const transcript = Array.from(event.results)
          .map((result) => result[0].transcript)
          .join(" ")
          .trim();
        const newSpokenWords = transcript.split(/\s+/).map(normalizeWord);
        setSpokenWords(newSpokenWords);
        analyzeWordAccuracy(newSpokenWords);
      };

      recognitionRef.current.onerror = (event) => { };

      recognitionRef.current.onend = () => {
        // Restart recognition if we're still in recording phase
        if (timerPhase === "recording" && isListening) {
          recognitionRef.current.start();
        }
      };
    }

    return () => {
      if (recordingTimeoutRef.current) {
        clearTimeout(recordingTimeoutRef.current);
      }
    };
  }, []);

  const normalizeWord = (word) => {
    return word
      .toLowerCase()
      .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "")
      .trim();
  };

  // Update `originalWordsRef` when `originalText` changes
  useEffect(() => {
    originalWordsRef.current = originalText.split(/(\s+)/);
    setWordStatus({});
    setSpokenWords([]);
    setScores({
      pronunciation: 0,
      fluency: 0,
      content: 0,
      totalScore: 0,
    });
  }, [originalText]);

  const analyzeWordAccuracy = (spokenWords) => {
    const normalizedOriginalWords = originalWordsRef.current
      .filter((word) => /\S/.test(word))
      .map(normalizeWord);

    const newWordStatus = {};
    normalizedOriginalWords.forEach((word, index) => {
      newWordStatus[index] = "missing";
    });

    const matchedIndexes = new Set();
    spokenWords.forEach((spokenWord) => {
      let found = false;
      normalizedOriginalWords.forEach((originalWord, index) => {
        if (!matchedIndexes.has(index) && spokenWord === originalWord) {
          newWordStatus[index] = "correct";
          matchedIndexes.add(index);
          found = true;
        }
      });
      if (!found) {
        newWordStatus[spokenWords.length + normalizedOriginalWords.length] =
          "incorrect";
      }
    });

    setWordStatus(newWordStatus);
    calculateScores(newWordStatus);
  };

  const calculateScores = (wordStatus) => {
    const normalizedOriginalWords = originalWordsRef.current.filter((word) =>
      /\S/.test(word)
    );
    const correctWords = Object.values(wordStatus).filter(
      (status) => status === "correct"
    ).length;

    const pronunciationScore = Math.round(
      (correctWords / normalizedOriginalWords.length) * 30
    );
    const spokenWordsCount = Object.values(wordStatus).filter(
      (status) => status === "correct" || status === "incorrect"
    ).length;

    const fluencyScore = Math.round(
      Math.min(1, spokenWordsCount / normalizedOriginalWords.length) * 30
    );

    const contentScore = Math.round(
      (correctWords / normalizedOriginalWords.length) * 30
    );

    setScores({
      pronunciation: pronunciationScore,
      fluency: fluencyScore,
      content: contentScore,
      totalScore: pronunciationScore + fluencyScore + contentScore,
    });
  };

  // useEffect(() => {
  //   // Simulate a user click by dispatching a fake click event
  //   const simulateUserGesture = () => {
  //     const event = new MouseEvent('click', {
  //       bubbles: true,
  //       cancelable: true,
  //     });
  //     document.body.dispatchEvent(event);
  //     console.log(event ,"event")
  //   };

  //   // Simulate the gesture on page load after a delay to avoid immediate issues
  //   setTimeout(() => {
  //     simulateUserGesture();
  //   }, 5000); // Adjust delay to make sure page is loaded before dispatching

  //   // Play beep sound when the page is loaded and gesture is simulated
  //   const playBeepSound = () => {
  //     createBeep();
  //     audio.play().catch((err) => {
  //       console.error('Error playing beep sound:', err);
  //     });
  //   };
  //   // Play sound after simulating the gesture
  //   setTimeout(playBeepSound, 5000);

  // }, []);

  const startListening = () => {
    if (recognitionRef.current) {
      try {
        setSpokenWords([]);
        setWordStatus({});
        setIsRecordingStopped(false);
        setRecordedAudio(null);
        setScores({
          pronunciation: 0,
          fluency: 0,
          content: 0,
          totalScore: 0,
        });
        setIsListening(true);
        // try {
        //   playBeep();
        // } catch (error) {
        //   console.warn('Audio playback failed:', error);
        //   provideAlternativeNotification();
        // }
        setPreparationTimer(0);
        setTimerPhase("recording");
        setRecordingTimer(40);
        recognitionRef.current.start();

        // Set timeout to stop recording after 40 seconds
        recordingTimeoutRef.current = setTimeout(() => {
          stopListening();
        }, 40000);
      } catch (error) {
        alert(
          "Could not start speech recognition. Please check microphone permissions."
        );
      }
    }
  };

  const stopListening = () => {
    if (recognitionRef.current) {
      recognitionRef.current.stop();
      setIsListening(false);
      setIsRecordingStopped(true);
      setTimerPhase("completed");

      if (recordingTimeoutRef.current) {
        clearTimeout(recordingTimeoutRef.current);
      }
    }
  };

  const onStop = (recordedBlob) => {
    setRecordedAudio(recordedBlob);
  };

  // const [checked, setChecked] = useState(
  //     !(SubCategory === "Repeat Sentence" || SubCategory === "Retell Lecture" || SubCategory === "Short Answer")
  // );

  // const handleToggle = (event) => {
  //     setChecked(event.target.checked);
  // };


  const handleToggle = (event) => {
    setChecked(event.target.checked);
  };

  const handleSubmitAnalysis = async () => {
    if (!recordedAudio) {
      alert("No recording available to analyze.");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("questionId", id);
      formData.append("ansfile", recordedAudio.blob);

      const score = {
        // category: Category,
        total: total,
        overall: scores.overall,
        content: scores.content,
        pronunciation: scores.pronunciation,
        fluency: scores.fluency,
        text: spokenWords.join(" "),
      };

      formData.append("score", JSON.stringify(score));

      const response = await postApi(API_PATH.STUDENTS.SUBMIT_ANS, formData);

      if (response.status === 201) {
        setOpenAnalysisModal(true);
        setColor(true);
      } else {
        alert("Failed to analyze the recording. Please try again.");
      }
    } catch (error) {
      alert("An error occurred while analyzing the recording.");
    }
  };

  return (
    <CardContent
      sx={{
        paddingX: "0px",
        width: "100%",
      }}
    >
      <ReactMic
        record={isListening}
        visualSetting="none"
        className="hidden d-none"
        onStop={onStop}
        mimeType="audio/webm"
      />

      <div className="mb-4">
        {(SubCategory !== "Repeat Sentence" ||
          SubCategory !== "Retell Lecture" ||
          SubCategory !== "Short Answer") &&
          checked === true &&
          color ? (
          <p className="text-white">
            {originalWordsRef.current.map((word, index) => (
              <span
                key={index}
                className={`${/\S/.test(word)
                  ? wordStatus[
                    originalWordsRef.current
                      .filter((w) => /\S/.test(w))
                      .indexOf(word)
                  ] === "correct"
                    ? "text-success font-bold"
                    : wordStatus[
                      originalWordsRef.current
                        .filter((w) => /\S/.test(w))
                        .indexOf(word)
                    ] === "missing"
                      ? "text-danger"
                      : "line-through"
                  : ""
                  }`}
              >
                {word}
              </span>
            ))}
          </p>
        ) : (
          checked === true && <p className="text-white">{originalText}</p>
        )}
      </div>

      {!isRecordingStopped && (
        <Box sx={{ textAlign: "center", paddingTop: "30px", my: "20px" }}>
          <IconButton
            onClick={isListening ? stopListening : startListening}
            // disabled={timerPhase === 'preparation'}
            sx={{
              bgcolor: isListening ? "#f44336" : "#4caf50",
              p: 4,
              mb: 2,
              "&:hover": {
                bgcolor: isListening ? "#d32f2f" : "#45a049",
              },
            }}
          >
            {isListening ? (
              <Stop sx={{ color: "white", fontSize: 40 }} />
            ) : (
              <Mic sx={{ color: "white", fontSize: 40 }} />
            )}
          </IconButton>
        </Box>
      )}
      {timerPhase !== "completed" && timerVisible && (
        <Typography
          variant="h6"
          sx={{ textAlign: "center", mb: 3, color: "#ffffff" }}
        >
          {timerPhase === "pre-preparation"
            ? `Preparation time : ${preparationTimer} sec`
            : timerPhase === "recording"
              ? `Recording time : ${recordingTimer} sec`
              : `Starts in : ${preparationTimer} sec`}
        </Typography>
      )}

      <div
        className="position-absolute bottom-0 w-100"
        style={{
          paddingRight: "49px",
        }}
      >
        {isRecordingStopped && recordedAudio && (
          <Box
            sx={{
              background: "white",
              borderRadius: "4px"
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{
                padding: "10px 0px",
                borderRadius: "8px",
              }}
            >
              <Switch
                checked={checked}
                onChange={handleToggle}
                color="success"
              />
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  marginLeft: "8px",
                }}
              >
                Show Answer
              </Typography>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "space-evenly", px: "50px" }}>
              <Typography sx={{ fontSize: "15px" }}><CheckBoxOutlineBlankIcon sx={{ backgroundColor: "green", color: "transparent !important", }} /> Good</Typography>
              <Typography sx={{ fontSize: "15px" }}><CheckBoxOutlineBlankIcon sx={{ backgroundColor: "yellow", color: "transparent !important" }} /> Average</Typography>
              <Typography sx={{ fontSize: "15px" }}><CheckBoxOutlineBlankIcon sx={{ backgroundColor: "red", color: "transparent !important" }} /> Bad</Typography>
              <Typography sx={{ fontSize: "15px" }}><CheckBoxOutlineBlankIcon sx={{ backgroundColor: "#EAE9E8", color: "transparent !important" }} /> Not Spoken</Typography>
            </Box>

            <Box
              sx={{
                pr: 2,
                display: "flex",
                background: "white",
                width: "100%",
                alignItems: "center",
                borderRadius: "6px",
                justifyContent: "space-evenly",
              }}
            >
              <AudioComponent src={recordedAudio.blobURL} />
              <Button
                variant="contained"
                sx={{
                  mt: 0,
                  bgcolor: "#4caf50",
                  color: "#fff",
                  width: "100%",
                  "&:hover": {
                    bgcolor: "#357a38",
                  },
                  "&.MuiButton-contained": {
                    backgroundColor: "#4caf50",
                    "&:hover": {
                      backgroundColor: "#357a38",
                    },
                  },
                }}
                onClick={handleSubmitAnalysis}
              >
                Analyze Recording
              </Button>
            </Box>
          </Box>
        )}
      </div>

      <Modal
        open={openAnalysisModal}
        onClose={() => setOpenAnalysisModal(false)}
        aria-labelledby="analysis-modal"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "auto",
            bgcolor: "#FFFFFF",
            borderRadius: "12px",
            p: 3,
            outline: "none",
            textAlign: "center",
          }}
        >
          {/* Green Checkmark Circle */}

          <Box
            sx={{
              width: "64px",
              height: "64px",
              bgcolor: "#00E676",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "0 auto 20px",
            }}
          >
            <i
              className="fas fa-check"
              style={{ color: "#FFFFFF", fontSize: "32px" }}
            />
          </Box>

          <Typography
            variant="h6"
            sx={{
              color: "#000000",
              fontSize: "24px",
              fontWeight: 500,
              mb: 3,
            }}
          >
            Score:{scores.totalScore}/{total}
          </Typography>
          {/* Score Categories */}

          <Box sx={{ mb: 3 }}>
            {[
              { label: "Content", score: scores.content, total: 30 },
              { label: "Fluency", score: scores.fluency, total: 30 },
              {
                label: "Pronunciation",
                score: scores.pronunciation,
                total: 30,
              },
            ].map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 2,
                  width: "450px",
                }}
              >
                <Typography
                  sx={{
                    flex: 1,
                    textAlign: "left",
                    color: "#666666",
                    width: "20%",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  {item.label}
                </Typography>

                <Box
                  sx={{
                    flex: 2,
                    height: "6px",
                    bgcolor: "#F0F0F0",
                    borderRadius: "3px",
                    position: "relative",
                    mx: 2,
                    width: "60%",
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      left: 0,
                      top: 0,
                      height: "100%",
                      paddingRight: `${(item.score / item.total) * 100}%`,
                      bgcolor: "#00E676",
                      borderRadius: "3px",
                    }}
                  />
                </Box>

                <Typography
                  sx={{ color: "#666666", minWidth: "70px", width: "20%" }}
                >
                  {item.score}/{item.total}
                </Typography>
              </Box>
            ))}
          </Box>
          {/* Transcription Display (Optional) */}
          {scores.content && (
            <Box sx={{ mb: 3, textAlign: "left" }}>
              <Typography sx={{ color: "#666666", fontWeight: 500, mb: 1 }}>
                Your Speech:
              </Typography>

              <Typography sx={{ color: "#666666", fontSize: "14px" }}>
                <p>{spokenWords.join(" ") || "No speech recorded"}</p>
              </Typography>
            </Box>
          )}
          {/* OK Button */}
          <Button
            fullWidth
            sx={{
              bgcolor: "#00E676",
              color: "#FFFFFF",
              borderRadius: "25px",
              py: 1.5,
              textTransform: "none",
              fontSize: "16px",
              "&:hover": {
                bgcolor: "#00C853",
              },
            }}
            onClick={() => setOpenAnalysisModal(false)}
          >
            Ok
          </Button>
        </Box>
      </Modal>
    </CardContent>
  );
};

export default PteSpeakingPractice;

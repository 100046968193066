import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Button,
  Box,
  Typography,
  Modal,
  IconButton,
  Switch,
} from "@mui/material";
import { ReactMic } from "react-mic";
import AudioComponent from "components/Recorder/AudioComponent";
import { Mic, Stop } from "@mui/icons-material";
import { getApi, postApi } from "services/axiosInstance";
import { API_PATH } from "services/apipath";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

const total = 90.0;

const PteSpeakingPractice = ({ originalText = "", id, SubCategory, timer }) => {
  const initalPrepTimer = timer;

  const [isListening, setIsListening] = useState(false);
  const [isRecordingStopped, setIsRecordingStopped] = useState(false);
  const [spokenWords, setSpokenWords] = useState([]);
  const [wordStatus, setWordStatus] = useState({});
  const [openAnalysisModal, setOpenAnalysisModal] = useState(false);
  const [recordedAudio, setRecordedAudio] = useState(null);
  const [preparationTimer, setPreparationTimer] = useState(initalPrepTimer);
  const [recordingTimer, setRecordingTimer] = useState(40);
  const [timerPhase, setTimerPhase] = useState("preparation"); // 'preparation' | 'recording' | 'completed'
  const [timerVisible, setTimerVisible] = useState(true);
  const [scores, setScores] = useState({
    pronunciation: 0,
    fluency: 0,
    content: 0,
    totalScore: 0,
  });

  useEffect(() => {
    setPreparationTimer(timer);
  }, [timer]);

  useEffect(() => {
    setIsListening(false)
    setIsRecordingStopped(false)
    setTimerPhase('preparation')
    setPreparationTimer(timer);
    setChecked(true)
  }, [id]);

  const originalWordsRef = useRef(originalText.split(/(\s+)/));
  const recognitionRef = useRef(null);
  const recordingTimeoutRef = useRef(null);

  const createBeep = () => {
    try {
      const audioContext = new AudioContext();
      const oscillator = audioContext.createOscillator();
      const gainNode = audioContext.createGain();

      // Connect the nodes
      oscillator.connect(gainNode);
      gainNode.connect(audioContext.destination);

      // Configure oscillator
      oscillator.type = "sine";
      oscillator.frequency.setValueAtTime(800, audioContext.currentTime); // Frequency in hertz

      // Configure gain (volume)
      gainNode.gain.setValueAtTime(0.5, audioContext.currentTime); // Start at 10% volume

      // Start and stop the beep
      oscillator.start(audioContext.currentTime);
      oscillator.stop(audioContext.currentTime + 0.15); // Beep duration: 150ms

      // Cleanup
      setTimeout(() => {
        gainNode.disconnect();
        oscillator.disconnect();
      }, 200);
    } catch (error) {
      console.error("Error creating beep:", error);
    }
  };

  // Timer logic for preparation phase
  useEffect(() => {
    if (timerPhase !== "preparation" || preparationTimer <= 0) return;

    const intervalId = setInterval(() => {
      setPreparationTimer((prev) => {
        if (prev === 2) {
          createBeep();
        }
        if (prev <= 1) {
          setTimerPhase("recording");
          startListening();
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [preparationTimer, timerPhase, id]);

  // Timer logic for recording phase
  useEffect(() => {
    if (timerPhase !== "recording" || !isListening) return;

    const intervalId = setInterval(() => {
      setRecordingTimer((prev) => {
        if (prev <= 1) {
          stopListening();
          setTimerPhase("completed");
          setTimerVisible(false);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [recordingTimer, timerPhase, isListening, id]);

  // Initialize Speech Recognition
  useEffect(() => {
    if ("webkitSpeechRecognition" in window) {
      recognitionRef.current = new window.webkitSpeechRecognition();
      recognitionRef.current.continuous = true;
      recognitionRef.current.interimResults = true;
      recognitionRef.current.lang = "en-US";

      recognitionRef.current.onresult = (event) => {
        console.log(event, "result");
        const transcript = Array.from(event.results)
          .map((result) => result[0].transcript)
          .join(" ")
          .trim();
        const newSpokenWords = transcript.split(/\s+/).map(normalizeWord);
        setSpokenWords(newSpokenWords);
        analyzeWordAccuracy(newSpokenWords);
      };

      recognitionRef.current.onerror = (event) => { };

      recognitionRef.current.onend = () => {
        // Restart recognition if we're still in recording phase
        if (timerPhase === "recording" && isListening) {
          recognitionRef.current.start();
        }
      };
    }

    return () => {
      if (recordingTimeoutRef.current) {
        clearTimeout(recordingTimeoutRef.current);
      }
    };
  }, [id]);

  const normalizeWord = (word) => {
    return word
      .toLowerCase()
      .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "")
      .trim();
  };

  useEffect(() => {
    originalWordsRef.current = originalText.split(/(\s+)/);
    setWordStatus({});
    setSpokenWords([]);
    setScores({
      pronunciation: 0,
      fluency: 0,
      content: 0,
      totalScore: 0,
    });
  }, [originalText]);

  const analyzeWordAccuracy = (spokenWords) => {
    const normalizedOriginalWords = originalWordsRef.current
      .filter((word) => /\S/.test(word))
      .map(normalizeWord);

    const newWordStatus = {};
    normalizedOriginalWords.forEach((word, index) => {
      newWordStatus[index] = "missing";
    });

    const matchedIndexes = new Set();
    spokenWords.forEach((spokenWord) => {
      let found = false;
      normalizedOriginalWords.forEach((originalWord, index) => {
        if (!matchedIndexes.has(index) && spokenWord === originalWord) {
          newWordStatus[index] = "correct";
          matchedIndexes.add(index);
          found = true;
        }
      });
      if (!found) {
        newWordStatus[spokenWords.length + normalizedOriginalWords.length] =
          "incorrect";
      }
    });

    setWordStatus(newWordStatus);
    calculateScores(newWordStatus);
  };

  const calculateScores = (wordStatus) => {
    const normalizedOriginalWords = originalWordsRef.current.filter((word) =>
      /\S/.test(word)
    );
    const correctWords = Object.values(wordStatus).filter(
      (status) => status === "correct"
    ).length;

    const pronunciationScore = Math.round(
      (correctWords / normalizedOriginalWords.length) * 30
    );
    const spokenWordsCount = Object.values(wordStatus).filter(
      (status) => status === "correct" || status === "incorrect"
    ).length;

    const fluencyScore = Math.round(
      Math.min(1, spokenWordsCount / normalizedOriginalWords.length) * 30
    );

    const contentScore = Math.round(
      (correctWords / normalizedOriginalWords.length) * 30
    );

    setScores({
      pronunciation: pronunciationScore,
      fluency: fluencyScore,
      content: contentScore,
      totalScore: pronunciationScore + fluencyScore + contentScore,
    });
  };

  const startListening = () => {
    if (recognitionRef.current) {
      try {
        setSpokenWords([]);
        setWordStatus({});
        setIsRecordingStopped(false);
        setRecordedAudio(null);
        setScores({
          pronunciation: 0,
          fluency: 0,
          content: 0,
          totalScore: 0,
        });
        setIsListening(true);
        // try {
        //   playBeep();
        // } catch (error) {
        //   console.warn('Audio playback failed:', error);
        //   provideAlternativeNotification();
        // }
        setPreparationTimer(0);
        setTimerPhase("recording");
        setRecordingTimer(40);
        recognitionRef.current.start();

        // Set timeout to stop recording after 40 seconds
        recordingTimeoutRef.current = setTimeout(() => {
          stopListening();
        }, 40000);
      } catch (error) {
        alert(
          "Could not start speech recognition. Please check microphone permissions."
        );
      }
    }
  };

  const stopListening = () => {
    if (recognitionRef.current) {
      recognitionRef.current.stop();
      setIsListening(false);
      setIsRecordingStopped(true);
      setTimerPhase("completed");

      if (recordingTimeoutRef.current) {
        clearTimeout(recordingTimeoutRef.current);
      }
    }
  };

  const onStop = (recordedBlob) => {
    setRecordedAudio(recordedBlob);
  };

  // const [checked, setChecked] = useState(
  //     !(SubCategory === "Repeat Sentence" || SubCategory === "Retell Lecture" || SubCategory === "Short Answer")
  // );

  // const handleToggle = (event) => {
  //     setChecked(event.target.checked);
  // };

  const [color, setColor] = useState(false);
  const [checked, setChecked] = useState(
    SubCategory === "Repeat Sentence" ||
      SubCategory === "Retell Lecture" ||
      SubCategory === "Short Answer"
      ? false
      : true
  );

  const handleToggle = (event) => {
    setChecked(event.target.checked);
  };

  const handleSubmitAnalysis = async () => {
    if (!recordedAudio) {
      alert("No recording available to analyze.");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("questionId", id);
      formData.append("ansfile", recordedAudio.blob);

      const score = {
        // category: Category,
        total: total,
        overall: scores.overall,
        content: scores.content,
        pronunciation: scores.pronunciation,
        fluency: scores.fluency,
        text: spokenWords.join(" "),
      };

      formData.append("score", JSON.stringify(score));

      const response = await postApi(API_PATH.STUDENTS.SUBMIT_ANS, formData);

      if (response.status === 201) {
        setOpenAnalysisModal(true);
        setColor(true);
      } else {
        alert("Failed to analyze the recording. Please try again.");
      }
    } catch (error) {
      alert("An error occurred while analyzing the recording.");
    }
  };

  return (
    <CardContent
      sx={{
        paddingX: "0px",
        width: "40%",
        border:"1px solid red",
        borderRadius: "8px",
      }}
    >
      <ReactMic
        record={isListening}
        visualSetting="none"
        className="hidden d-none"
        onStop={onStop}
        mimeType="audio/webm"
      />
      <div className="">
        {(SubCategory !== "Repeat Sentence" ||
          SubCategory !== "Retell Lecture" ||
          SubCategory !== "Short Answer") &&
          checked === true &&
          color ? (
          <p className="text-white">
            {originalWordsRef.current.map((word, index) => (
              <span
                key={index}
                className={`${/\S/.test(word)
                  ? wordStatus[
                    originalWordsRef.current
                      .filter((w) => /\S/.test(w))
                      .indexOf(word)
                  ] === "correct"
                    ? "text-success font-bold"
                    : wordStatus[
                      originalWordsRef.current
                        .filter((w) => /\S/.test(w))
                        .indexOf(word)
                    ] === "missing"
                      ? "text-danger"
                      : "line-through"
                  : ""
                  }`}
              >
                {word}
              </span>
            ))}
          </p>
        ) : checked === true && (
          <p className="text-white">{originalText}</p>
        )}
      </div>

      {!isRecordingStopped && (
        <Box sx={{ textAlign: "center", mb: "15px" }}>
          {/* <IconButton
            onClick={isListening ? stopListening : startListening}
            sx={{
              bgcolor: isListening ? "#f44336" : "#4caf50",
              p: 4,
              mb: 2,
              "&:hover": {
                bgcolor: isListening ? "#d32f2f" : "#45a049",
              },
            }}
          >
            {isListening ? (
              <Stop sx={{ color: "white", fontSize: 40 }} />
            ) : (
              <Mic sx={{ color: "white", fontSize: 40 }} />
            )}
          </IconButton> */}
          <Typography>Voice Recorder</Typography>
        </Box>
      )}
      {timerPhase !== "completed" && timerVisible && (
        <Typography
          variant="h6"
          sx={{ textAlign: "center", mb: 3, color: "black" }}
        >
          {timerPhase === "preparation"
            ? `Recording In : ${preparationTimer} sec`
            : `Recording Time : ${recordingTimer} sec`}
        </Typography>
      )}

    </CardContent>
  );
};

export default PteSpeakingPractice;

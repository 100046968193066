import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  IconButton,
  Select,
  MenuItem,
  Paper,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate } from "react-router-dom";
import { QuestionCard } from "../../../../components/QuestionCard/QuestionCard";
import Pagination from "components/Pagination/Pagination";
import { postApi } from "../../../../services/axiosInstance";
import { API_PATH } from "../../../../services/apipath.js";

function Questions() {
  const navigate = useNavigate();

  // State variables
  const [questions, setQuestions] = useState([]);
  const [filterType, setFilterType] = useState("all");
  const [currentPage, setCurrentPage] = useState(
    JSON.parse(localStorage.getItem("currentPage")) || 1
  );
  const [totalQuestions, setTotalQuestions] = useState(0); // Track total questions
  const ITEMS_PER_PAGE = 10;

  // Dynamic variables for API
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const Category = params.get("category");
  const subCategory = params.get("subcategory");
  const base = params.get("base");

  // API Call: Fetch questions
  const getQuestionsList = async () => {
    try {
      const res = await postApi(
        `${API_PATH.STUDENTS.GET_QUESTIONS_SPEAKING_READALOUD}/${Category}`,
        {
          subcategory: subCategory,
          page: currentPage,
          limit: ITEMS_PER_PAGE,
          base: base !== "undefined" ? base : "",
        }
      );

      if (res.status === 200) {
        setQuestions(res.data.data || []); // Set questions
        setTotalQuestions(res.data.pagination.totalQuestions || 0); // Set total questions count
      } else {
      }
    } catch (error) {}
  };

  // Fetch questions whenever the page changes or the component mounts
  useEffect(() => {
     
    getQuestionsList();
  }, [currentPage]);

  // Handle filter change
  const handleFilterChange = (event) => {
    setFilterType(event.target.value);
    // setCurrentPage(1); // Reset to first page when changing filter
  };

  // Filter questions based on the selected filter type
  const getFilteredQuestions = () => {
    switch (filterType) {
      case "attempted":
        return questions.filter((q) => q.attemptCount > 0);
      case "unattempted":
        return questions.filter((q) => q.attemptCount === 0);
      default:
        return questions;
    }
  };

  // Handle pagination change
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
      window.scrollTo(0, 0); // Scroll to top when the page changes
      localStorage.setItem("currentPage", newPage);
    }
  };

 
  // Calculate total pages based on total questions and ITEMS_PER_PAGE
  const totalPages = Math.ceil(totalQuestions / ITEMS_PER_PAGE);

  // Get current page questions
  const getCurrentPageQuestions = () => {
    const filteredQuestions = getFilteredQuestions();
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    // .slice(startIndex, endIndex);
    return filteredQuestions;
  };

  // Handle Close Click (if applicable)
  const handleClose = () => {
    localStorage.setItem("currentPage", 1);
    navigate(-1); // Navigate back to the previous page
  };

  // Handle new question click (if applicable)
  const handleNewClick = (id) => {
    navigate(`/questions/${id}${location.search}`, {
      state: questions,
    });
  };

  return (
    <Box sx={{ bgcolor: "#f8f9fa", minHeight: "100vh", p: 2 }}>
      <Paper
        elevation={1}
        sx={{
          bgcolor: "#fff",
          borderRadius: 2,
          overflow: "hidden",
        }}
      >
        {/* Header */}
        <Box
          sx={{
            bgcolor: "#1a1a2e",
            p: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <IconButton sx={{ color: "#fff" }} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" sx={{ color: "#fff" }}>
            Questions
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Select
              value={filterType}
              onChange={handleFilterChange}
              size="small"
              sx={{
                color: "#fff",
                minWidth: "150px",
                height: "32px",
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(255, 255, 255, 0.5)",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(255, 255, 255, 0.8)",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(255, 255, 255, 0.8)",
                },
                ".MuiSvgIcon-root": {
                  color: "#fff",
                },
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    bgcolor: "#1a1a2e",
                  },
                },
              }}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="attempted">Attempted</MenuItem>
              <MenuItem value="unattempted">Unattempted</MenuItem>
            </Select>
            <Typography sx={{ color: "#fff", width: "100px" }}>
              {totalQuestions} Qs
            </Typography>
          </Box>
        </Box>
        {/* Questions List */}
        <Box sx={{ p: 2 }}>
          {getCurrentPageQuestions().length > 0 ? (
            getCurrentPageQuestions().map((question, index) => (
              <QuestionCard
                title={question.question_title}
                key={index} // Use _id for unique key
                id={question._id}
                attempts={question.attemptCount ? question.attemptCount : 0}
                number={(currentPage - 1) * ITEMS_PER_PAGE + index + 1}
                onNewClick={handleNewClick}
                {...question}
              />
            ))
          ) : (
            <Typography>No questions available</Typography>
          )}
        </Box> 
        {/* Pagination */}
        <Box
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderTop: "1px solid rgba(255, 255, 255, 0.1)",
            bgcolor: "#1a1a2e",
          }}
        >
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </Box>
      </Paper>
    </Box>
  );
}

export default Questions;

import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import colors from "assets/theme/base/colors";

const { dark, light } = colors;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  borderRadius: 5,
  borderWidth: 3,
  borderColor: dark.main,
  borderStyle: "solid",
  p: 3,
};

const AlertModal = ({ open, handleClose, msg }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-modal-title"
      aria-describedby="alert-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}
        >
          {/* Header */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottomWidth: 3,
              borderBottomColor: light.main,
              borderBottomStyle: "solid",
              pb: 2,
            }}
          >
            <Typography
              id="alert-modal-title"
              variant="h6"
              sx={{ fontWeight: "bold" }}
            >
              Alert
            </Typography>
            <IconButton
              sx={{ p: 0 }}
              onClick={handleClose}
              aria-label="close"
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </Box>
          {/* Content */}
          <Box sx={{ mt: 3 }}>
            <Typography
              id="alert-modal-description"
              sx={{ fontSize: "1rem", color: "#292a2d" }}
            >
              {msg}
            </Typography>
          </Box>
          {/* Footer */}
          <Box sx={{ mt: 3, textAlign: "right" }}>
            <Button
              variant="contained"
              size="small"
              onClick={handleClose}
              sx={{
                bgcolor: light.main,
                "&:hover": { bgcolor: light.focus },
                textTransform: "capitalize",
              }}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default AlertModal;

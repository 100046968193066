import React, { useState, useEffect, useRef } from "react";
import { IconButton, Typography, Box } from "@mui/material";
import { Mic, Stop } from "@mui/icons-material";
import lamejs from "lamejs";

export const ReadAloudMicrophoneRecorder = ({
  onRecordingComplete,
  expectedText,
}) => {
  const [isRecording, setIsRecording] = useState(false);
  const [timer, setTimer] = useState(40);
  const [transcription, setTranscription] = useState("");
  const [scores, setScores] = useState(null); // Store the calculated scores

  const mediaRecorderRef = useRef(null);
  const chunksRef = useRef([]);
  const streamRef = useRef(null);
  const timerIntervalRef = useRef(null);
  const speechRecognitionRef = useRef(null);

  // Initialize speech recognition
  useEffect(() => {
    if ("webkitSpeechRecognition" in window) {
      const recognition = new window.webkitSpeechRecognition();
      recognition.continuous = true;
      recognition.interimResults = true;
      recognition.lang = "en-US";

      recognition.onresult = (event) => {
        let finalTranscript = "";
        for (let i = event.resultIndex; i < event.results.length; i++) {
          if (event.results[i].isFinal) {
            finalTranscript += event.results[i][0].transcript;
          }
        }
        setTranscription(finalTranscript);
      };

      speechRecognitionRef.current = recognition;
    }
  }, []);

  // Cleanup
  useEffect(() => {
    return () => {
      if (timerIntervalRef.current) {
        clearInterval(timerIntervalRef.current);
      }
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);

  const getMimeType = () => {
    const types = [
      "audio/webm",
      "audio/webm;codecs=opus",
      "audio/ogg;codecs=opus",
      "audio/mp3", // MP3 format (not always supported in all browsers)
      "audio/wav",
    ];
    for (const type of types) {
      if (MediaRecorder.isTypeSupported(type)) {
        return type;
      }
    }
    return "audio/webm";
  };

  const calculateScores = (transcript, expectedText, timer) => {
    if (!transcript || !expectedText || !timer) {
      return;
    }

    // Content score based on text similarity
    const words1 = transcript.toLowerCase().split(" ");
    const words2 = expectedText.toLowerCase().split(" ");
    let matches = 0;
    words1.forEach((word) => {
      if (words2.includes(word)) matches++;
    });
    const contentScore = Math.round(
      (matches / Math.max(words1.length, words2.length)) * 90
    );

    // Pronunciation score (placeholder - would need actual assessment logic)
    const pronunciationScore = Math.min(
      90,
      Math.max(60, calculatePronunciationScore(transcript))
    );

    // Fluency score calculation
    const wordsPerMinute = (transcript.split(" ").length / timer) * 60;
    const fluencyScore = calculateFluencyScore(wordsPerMinute);

    return {
      content: contentScore,
      pronunciation: pronunciationScore,
      fluency: fluencyScore,
      overall: Math.round(
        (contentScore + pronunciationScore + fluencyScore) / 3
      ),
    };
  };

  const calculatePronunciationScore = (transcript) => {
    const words = transcript.split(" ").length;

    if (words < 5) {
      return 65;
    } else if (words < 10) {
      return Math.round(Math.random() * 10 + 70);
    } else {
      return Math.round(Math.random() * 20 + 80);
    }
  };

  function calculateFluencyScore(wordsPerMinute) {
    if (wordsPerMinute < 50) return 60;
    if (wordsPerMinute < 100) return 75;
    if (wordsPerMinute < 150) return 85;
    return 90;
  }

  // const convertBlobToMP3 = (audioBlob) => {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();

  //     reader.onload = () => {
  //       const arrayBuffer = reader.result;

  //       const audioContext = new (window.AudioContext || window.webkitAudioContext)();

  //       audioContext.decodeAudioData(arrayBuffer, (audioBuffer) => {
  //         const pcmData = audioBuffer.getChannelData(0);
  //         const samples = new Int16Array(pcmData.length);

  //         // Convert to 16-bit PCM samples
  //         for (let i = 0; i < pcmData.length; i++) {
  //           samples[i] = pcmData[i] * 32767;
  //         }

  //         try {
  //           // Now `lamejs` is available globally
  //           const mp3Encoder = new lamejs.Mp3Encoder(1, 44100, 128);
  //           const mp3Data = mp3Encoder.encodeBuffer(samples);
  //           const mp3Blob = new Blob([mp3Data], { type: 'audio/mp3' });

  //           resolve(mp3Blob);
  //         } catch (error) {
  //           reject(new Error('Error initializing Mp3Encoder or encoding the audio: ' + error.message));
  //         }
  //       }, (error) => {
  //         reject(new Error('Error decoding audio: ' + error.message));
  //       });
  //     };

  //     reader.onerror = () => {
  //       reject(new Error('Error reading audio Blob'));
  //     };

  //     reader.readAsArrayBuffer(audioBlob);
  //   });
  // };

  // const convertToMP3 = async (blob) => {
  //   // setLoading(true);
  //   await loadFFmpeg();

  //   const audioFile = new Blob([blob], { type: 'audio/wav' });
  //   const fileReader = new FileReader();
  //   fileReader.onload = async () => {
  //     const audioData = new Uint8Array(fileReader.result);

  //     // Write the data to FFmpeg's virtual file system
  //     await ffmpeg.FS('writeFile', 'input.wav', audioData);

  //     // Run FFmpeg to convert to MP3
  //     await ffmpeg.run('-i', 'input.wav', 'output.mp3');

  //     // Read the MP3 file from FFmpeg's virtual file system
  //     const mp3Data = ffmpeg.FS('readFile', 'output.mp3');

  //     // Create a Blob URL for the MP3 file
  //     return new Blob([mp3Data.buffer], { type: 'audio/mp3' });
  //     // const url = URL.createObjectURL(mp3Blob);
  //     // setAudioUrl(url);
  //     // setLoading(false);
  //   };
  //   fileReader.readAsArrayBuffer(audioFile);
  // };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      streamRef.current = stream;

      const mediaRecorder = new MediaRecorder(stream, {
        mimeType: getMimeType(),
      });

      mediaRecorderRef.current = mediaRecorder;
      chunksRef.current = [];

      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunksRef.current.push(event.data);
        }
      };

      mediaRecorder.onstop = async () => {
        const audioBlob = new Blob(chunksRef.current, { type: 'audio/mp3' });
        const audioUrl = URL.createObjectURL(audioBlob);
        // const mp3 = await convertBlobToMP3(audioBlob);
        const scores = await calculateScores(
          transcription,
          expectedText,
          timer
        );
        setScores(scores); 

        onRecordingComplete?.({
          blob: audioBlob,
          url: audioUrl,
          mimeType: getMimeType(),
          transcription: transcription,
          scores: scores,
        });
      };

      if (speechRecognitionRef.current) {
        speechRecognitionRef.current.start();
      }

      mediaRecorder.start();
      setIsRecording(true);

      timerIntervalRef.current = setInterval(() => {
        setTimer((prev) => {
          if (prev <= 1) {
            stopRecording();
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    } catch (err) {
    }
  };

  const stopRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "inactive"
    ) {
      try {
        mediaRecorderRef.current.stop();
        if (speechRecognitionRef.current) {
          speechRecognitionRef.current.stop();
        }
        if (streamRef.current) {
          streamRef.current.getTracks().forEach((track) => track.stop());
        }
        if (timerIntervalRef.current) {
          clearInterval(timerIntervalRef.current);
        }
        setIsRecording(false);
        setTimer(40);
      } catch (err) {
      }
    }
  };

  return (
    <Box sx={{ textAlign: "center" }}>
      <IconButton
        onClick={isRecording ? stopRecording : startRecording}
        sx={{
          bgcolor: isRecording ? "#f44336" : "#4caf50",
          p: 4,
          mb: 2,
          "&:hover": {
            bgcolor: isRecording ? "#d32f2f" : "#45a049",
          },
        }}
      >
        {isRecording ? (
          <Stop sx={{ color: "white", fontSize: 40 }} />
        ) : (
          <Mic sx={{ color: "white", fontSize: 40 }} />
        )}
      </IconButton>
      <Typography color="text.secondary">
        {isRecording
          ? `Recording... ${timer}s remaining`
          : timer === 40
          ? "Click to start recording"
          : "Recording completed"}
      </Typography>
      {/* {transcription && (
        <Typography sx={{ mt: 2, fontSize: "14px", color: "text.secondary" }}>
          Transcription: {transcription}
        </Typography>
      )} */}

      {/* Display Scores */}
      {/* {scores && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6">Scores:</Typography>
          <Typography color="text.secondary">
            Content Score: {scores.content}
          </Typography>
          <Typography color="text.secondary">
            Pronunciation Score: {scores.pronunciation}
          </Typography>
          <Typography color="text.secondary">
            Fluency Score: {scores.fluency}
          </Typography>
          <Typography color="text.secondary">
            Overall Score: {scores.overall}
          </Typography>
        </Box>
      )} */}
    </Box>
  );
};

export default ReadAloudMicrophoneRecorder;

import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  IconButton,
  Button,
  TextField,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { getApi } from "services/axiosInstance";
import { API_PATH } from "services/apipath";
import { processQuestionText } from "utils/ProcessQuestionText"; 
import AudioPlayer from "layouts/listening/AudioPlayer";

function FillInBlanksAnswerMock({ questionData }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showAnswer, setShowAnswer] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [blanks, setBlanks] = useState({});
  const [correctAnswers, setCorrectAnswers] = useState([]);

  const getQuestionIdFromUrl = () => {
    const pathSegments = location.pathname.split("/");
    return pathSegments[pathSegments.length - 1];
  };

  const questionId = getQuestionIdFromUrl();
  const [questionDataApi, setQuestionDataApi] = useState([]);
  const [questionid, setQuestionId] = useState();
  let id = location.pathname.split("/")[2];
  const getQuestionBtID = async (id) => {
    try {
      const res = await getApi(`${API_PATH.STUDENTS.GET_QUESTION_BY_ID}/${id}`);
      if (res.status === 200) {
        setQuestionDataApi(res.data.data);
      }
    } catch (error) { }
  };

  useEffect(() => {
    if (id && questionid === undefined) {
      getQuestionBtID(id);
    }
  }, []);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        setLoading(true);
        const response = await getApi(
          `v2/student/get-questions?ids=${questionId}`
        );

        if (response.data && response.data.data) {
          const filteredQuestion = response.data.data.filter(
            (question) => question._id === questionId
          );

          setQuestions(filteredQuestion);

          if (filteredQuestion[0]) {
            const { totalBlanks } = processQuestionText(
              filteredQuestion[0].question_text
            );

            // Initialize blanks
            const initialBlanks = {};
            for (let i = 1; i <= totalBlanks; i++) {
              initialBlanks[`blank${i}`] = "";
            }
            setBlanks(initialBlanks);

            // Store correct answers for comparison and "Show Answer"
            setCorrectAnswers(filteredQuestion[0].FillInTheBlanksAnswers);
          }
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    if (questionId) {
      fetchQuestions();
    }
  }, [questionId]);

  const currentQuestion = questions[currentQuestionIndex];

  const handleInputChange = (event, blankId) => {
    setBlanks({
      ...blanks,
      [blankId]: event.target.value,
    });
    setIsSubmitted(false);
  };

  const handleSubmit = () => {
    if (!currentQuestion || !correctAnswers.length) return;

    const newBlankStatus = {};

    // Validate each blank
    Object.entries(blanks).forEach(([blankId, value], index) => {
      newBlankStatus[blankId] = value.trim() === correctAnswers[index];
    });

    setIsSubmitted(true);

    // Check if all blanks are correct
    const isAllCorrect = Object.values(newBlankStatus).every((status) => status);
    setIsCorrect(isAllCorrect);
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex((prev) => prev + 1);
      const nextQuestion = questions[currentQuestionIndex + 1];
      if (nextQuestion) {
        const { totalBlanks } = processQuestionText(nextQuestion.question_text);
        const newBlanks = {};
        for (let i = 1; i <= totalBlanks; i++) {
          newBlanks[`blank${i}`] = "";
        }
        setBlanks(newBlanks);
      }
      setIsSubmitted(false);
      setIsCorrect(false);
      setShowAnswer(false);
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          bgcolor: "#1A1B2E",
          color: "#fff",
        }}
      >
        Loading...
      </Box>
    );
  }

  return (
    <Box sx={{ bgcolor: "#1A1B2E", minHeight: "100vh", color: "#fff", paddingX: "20px" }}>
      {/* Header */}
      <Box
        sx={{
          p: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid rgba(255,255,255,0.1)",
          position: "sticky",
          top: 0,
          zIndex: 1000,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton sx={{ color: "#fff" }} onClick={() => navigate(-1)}>
            <i className="fas fa-arrow-left" />
          </IconButton>
          <Typography sx={{ ml: 2, fontSize: "20px" }}>Fill in Blanks</Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography>
            {currentQuestionIndex + 1}/{questions.length}
          </Typography>
        </Box>
      </Box>

      {/* Main Content */}
      <Box sx={{ px: 2, pt: 3 }}>
        {currentQuestion && (
          <>
            <Typography
              sx={{
                color: "rgba(255,255,255,0.8)",
                mb: 2,
                fontStyle: "italic",
              }}
            >
              In the text below some words are missing. Type the correct words
              in the input fields below.
            </Typography>

            <AudioPlayer
              audioText={JSON.stringify(questionDataApi.audio_text)}
              delay={3}
            />

            <Box sx={{ mt: 4, lineHeight: 1.8, fontSize: "19px" }}>
              {(() => {
                const { parts } = processQuestionText(
                  currentQuestion.question_text
                );
                return parts.map((part, index, array) => (
                  <React.Fragment key={index}>
                    {part}
                    {index < array.length - 1 && (
                      <TextField
                        variant="outlined"
                        size="small"
                        value={
                          showAnswer
                            ? correctAnswers[index]
                            : blanks[`blank${index + 1}`] || ""
                        }
                        onChange={(e) =>
                          handleInputChange(e, `blank${index + 1}`)
                        }
                        sx={{
                          mx: 1,
                          width: "120px",
                          backgroundColor: "white",
                        }}
                        disabled={showAnswer} // Disable input when showing answers
                      />
                    )}
                  </React.Fragment>
                ));
              })()}
            </Box>

            {isSubmitted && (
              <Box
                sx={{
                  mt: 3,
                  p: 2,
                  bgcolor: isCorrect
                    ? "rgba(76, 175, 80, 0.1)"
                    : "rgba(244, 67, 54, 0.1)",
                  borderRadius: 1,
                  border: `1px solid ${isCorrect ? "#4CAF50" : "#f44336"}`,
                }}
              >
                <Typography
                  sx={{
                    color: isCorrect ? "#4CAF50" : "#f44336",
                    fontWeight: "bold",
                  }}
                >
                  {isCorrect ? "Correct!" : "Incorrect. Try again!"}
                </Typography>
              </Box>
            )}
          </>
        )}
      </Box>

      {/* Footer */}
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          paddingX: "40px",
          paddingBottom: "30px",
          display: "flex",
          justifyContent: "space-between",
          bgcolor: "#1A1B2E",
        }}
      >
        <FormControlLabel
          control={
            <Switch
              checked={showAnswer}
              onChange={(e) => setShowAnswer(e.target.checked)}
              sx={{
                "& .MuiSwitch-switchBase.Mui-checked": {
                  color: "#00A693",
                },
              }}
            />
          }
          label="Show Answer"
          sx={{ color: "#fff" }}
        />

        <Button
          variant="contained"
          onClick={handleSubmit}
          sx={{
            bgcolor: "#00A693",
            color: "#fff",
            "&:hover": { bgcolor: "#009688" },
          }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
}

export default FillInBlanksAnswerMock;

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

//  Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

//  Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

// ReactMic component
import React, { useState } from "react";
import { ReactMic } from "react-mic";

function MicTest() {
  const [recording, setRecording] = useState(false);
  const [recordedBlob, setRecordedBlob] = useState(null);

  const startRecording = () => {
    setRecording(true);
  };

  const stopRecording = () => {
    setRecording(false);
  };

  const onStop = (blob) => {
    setRecordedBlob(blob);
  };

  const downloadAudio = () => {
    if (recordedBlob) {
      const url = URL.createObjectURL(recordedBlob.blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = "recorded-audio.wav";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }
  };

  return (
    <div>
      <h2>Mic Test</h2>
      <ReactMic
        record={recording}
        onStop={onStop}
        strokeColor="#000000"
        backgroundColor="#F8F9FA"
      />
      <div style={{ marginTop: "10px", display:"flex", width:"125%" }}>
        <button onClick={startRecording} disabled={recording}
          style={{
            background: 'linear-gradient(90deg, rgb(81, 230, 255), rgb(23, 162, 184))',
            border: "none",
            color: "#fff",
            padding: "10px 20px",
            borderRadius: "5px",
            cursor: "pointer",
            fontWeight: "bold",
            opacity: recording ? 0.6 : 1,
            pointerEvents: recording ? "none" : "auto",
          }}>
          Start Recording
        </button>
        <button onClick={stopRecording} disabled={!recording}
          style={{
            background: 'linear-gradient(90deg, rgb(81, 230, 255), rgb(23, 162, 184))',
            border: "none",
            color: "#fff",
            padding: "10px 20px",
            borderRadius: "5px",
            cursor: "pointer",
            fontWeight: "bold",
            margin:"0 12px"
          }}
        >
          Stop Recording
        </button>
        <button onClick={downloadAudio} disabled={!recordedBlob}
          style={{
            background: 'linear-gradient(90deg, rgb(81, 230, 255), rgb(23, 162, 184))',
            border: "none",
            color: "#fff",
            padding: "8px 15px",
            borderRadius: "5px",
            cursor: "pointer",
            fontWeight: "bold",
          }}>
          Download Recording
        </button>
      </div>
    </div>
  );
}

function Mictestmain() {
  return (
    <DashboardLayout>
      <SoftBox mb={3}>
        <Card>
          <SoftBox pt={2} px={2}>
            <SoftBox mb={0.5}>
              <SoftTypography variant="h6" fontWeight="medium">
                Mic Testing
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          {/* <SoftBox> */}
          <Grid container spacing={0} sx={{ paddingX: "16px", paddingBottom: "16px" }}>
            <Grid item xs={12} md={6}>
              <MicTest />
            </Grid>
          </Grid>
          {/* </SoftBox> */}
        </Card>
      </SoftBox>
    </DashboardLayout>
  );
}

export default Mictestmain;

import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  IconButton,
  Button,
  Modal,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useLocation, useNavigate } from "react-router-dom";
import { getApi } from "services/axiosInstance";
import { processQuestionText } from "utils/ProcessQuestionText";

const searchItems = [
  { id: "SA #82", attempted: 0 },
  { id: "SA #81", attempted: 0 },
  { id: "SA #80", attempted: 0 },
  { id: "SA #78", attempted: 0 },
  { id: "SA #77", attempted: 0 },
];

function FillInBlanks({ questionData }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showAnswer, setShowAnswer] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [blankStatus, setBlankStatus] = useState({});

  const getQuestionIdFromUrl = () => {
    const pathSegments = location.pathname.split("/");
    return pathSegments[pathSegments.length - 1];
  };

  const questionId = getQuestionIdFromUrl();

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        setLoading(true);
        const response = await getApi(
          `v2/student/get-questions?ids=${questionId}`
        );

        if (response.data && response.data.data) {
          const filteredQuestion = response.data.data.filter(
            (question) => question._id === questionId
          );

          setQuestions(filteredQuestion);
          if (filteredQuestion[0]) {
            // Initialize available words
            setAvailableWords(
              filteredQuestion[0].choices.map((choice) => ({
                id: choice._id,
                content: choice.choice_text,
              }))
            );

            // Initialize blanks based on number of placeholders
            const { totalBlanks } = processQuestionText(
              filteredQuestion[0].question_text
            );
            const initialBlanks = {};
            for (let i = 1; i <= totalBlanks; i++) {
              initialBlanks[`blank${i}`] = null;
            }
            setBlanks(initialBlanks);
          }
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    if (questionId) {
      fetchQuestions();
    }
  }, [questionId]);

  const [availableWords, setAvailableWords] = useState([]);
  const [blanks, setBlanks] = useState({
    blank1: null,
  });

  const currentQuestion = questions[currentQuestionIndex];

  const handleSubmit = () => {
    if (!currentQuestion || !currentQuestion.FillInTheBlanksAnswers) return;

    const correctAnswers = currentQuestion.FillInTheBlanksAnswers;
    const newBlankStatus = {};

    // Check each blank individually
    Object.entries(blanks).forEach(([blankId, value], index) => {
      if (value) {
        newBlankStatus[blankId] = value.content === correctAnswers[index];
      }
    });

    setBlankStatus(newBlankStatus);
    setIsSubmitted(true);

    // Check if all answers are correct
    const isAllCorrect = Object.values(newBlankStatus).every(
      (status) => status
    );
    setIsCorrect(isAllCorrect);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;

    if (
      source.droppableId === "availableWords" &&
      destination.droppableId.startsWith("blank")
    ) {
      const word = availableWords[source.index];
      const newAvailableWords = [...availableWords];
      newAvailableWords.splice(source.index, 1);

      if (blanks[destination.droppableId]) {
        newAvailableWords.push(blanks[destination.droppableId]);
      }

      setAvailableWords(newAvailableWords);
      setBlanks({
        ...blanks,
        [destination.droppableId]: word,
      });
      // Reset submission state when user makes changes
      setBlankStatus({});
      setIsSubmitted(false);
    }

    if (
      source.droppableId.startsWith("blank") &&
      destination.droppableId === "availableWords"
    ) {
      const word = blanks[source.droppableId];
      setAvailableWords([...availableWords, word]);
      setBlanks({
        ...blanks,
        [source.droppableId]: null,
      });
      // Reset submission state when user makes changes
      setBlankStatus({});
      setIsSubmitted(false);
    }
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex((prev) => prev + 1);
      const nextQuestion = questions[currentQuestionIndex + 1];
      if (nextQuestion) {
        const { totalBlanks } = processQuestionText(nextQuestion.question_text);
        const newBlanks = {};
        for (let i = 1; i <= totalBlanks; i++) {
          newBlanks[`blank${i}`] = null;
        }
        setBlanks(newBlanks);
        setAvailableWords(
          nextQuestion.choices.map((choice) => ({
            id: choice._id,
            content: choice.choice_text,
          }))
        );
      }
      setShowAnswer(false);
      setBlankStatus({});
      setIsSubmitted(false);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex((prev) => prev - 1);
      const prevQuestion = questions[currentQuestionIndex - 1];
      if (prevQuestion) {
        const { totalBlanks } = processQuestionText(prevQuestion.question_text);
        const newBlanks = {};
        for (let i = 1; i <= totalBlanks; i++) {
          newBlanks[`blank${i}`] = null;
        }
        setBlanks(newBlanks);
        setAvailableWords(
          prevQuestion.choices.map((choice) => ({
            id: choice._id,
            content: choice.choice_text,
          }))
        );
      }
      setShowAnswer(false);
      setBlankStatus({});
      setIsSubmitted(false);
    }
  };

  const renderBlank = (blankId) => (
    <Droppable droppableId={blankId}>
      {(provided, snapshot) => (
        <Box
          ref={provided.innerRef}
          {...provided.droppableProps}
          sx={{
            display: "inline-block",
            mx: 1,
            minWidth: 120,
            minHeight: 32,
            border: "1px solid rgba(255,255,255,0.3)",
            borderRadius: 1,
            bgcolor: snapshot.isDraggingOver
              ? "rgba(255,255,255,0.2)"
              : "rgba(255,255,255,0.1)",
            p: 0.5,
            transition: "background-color 0.2s ease",
          }}
        >
          {blanks[blankId] && (
            <Draggable draggableId={blanks[blankId].id} index={0}>
              {(provided, snapshot) => (
                <Box
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  sx={{
                    p: 1,
                    bgcolor: isSubmitted
                      ? blankStatus[blankId]
                        ? "#4CAF50" // Green for correct
                        : "#f44336" // Red for incorrect
                      : "#00A693", // Default color
                    borderRadius: 1,
                    boxShadow: snapshot.isDragging ? 3 : 0,
                  }}
                >
                  {blanks[blankId].content}
                </Box>
              )}
            </Draggable>
          )}
          {provided.placeholder}
        </Box>
      )}
    </Droppable>
  );

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          bgcolor: "#1A1B2E",
          color: "#fff",
        }}
      >
        Loading...
      </Box>
    );
  }

  return (
    <Box sx={{ bgcolor: "#1A1B2E", minHeight: "100vh", color: "#fff" }}>
      {/* Header */}
      <Box
        sx={{
          bgcolor: "#1A1B2E",
          p: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid rgba(255,255,255,0.1)",
          position: "sticky",
          top: 0,
          zIndex: 1000,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton sx={{ color: "#fff" }} onClick={() => navigate(-1)}>
            <i className="fas fa-arrow-left" />
          </IconButton>
          <Typography sx={{ ml: 2, fontSize: "20px" }}>
            Fill in Blanks
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography>{`${currentQuestionIndex + 1}/${
            questions.length
          }`}</Typography>
          <IconButton
            sx={{ color: "#fff" }}
            onClick={() => setOpenSearchModal(true)}
          >
            <i className="fas fa-search" />
          </IconButton>
          <IconButton sx={{ color: "#fff" }} onClick={handlePreviousQuestion}>
            <i className="fas fa-chevron-left" />
          </IconButton>
          <IconButton sx={{ color: "#fff" }} onClick={handleNextQuestion}>
            <i className="fas fa-chevron-right" />
          </IconButton>
        </Box>
      </Box>

      {/* Main Content */}
      <Box sx={{ px: 2, pt: 3, pb: "80px" }}>
        {currentQuestion && (
          <>
            <Typography
              sx={{
                color: "rgba(255,255,255,0.8)",
                mb: 2,
                fontStyle: "italic",
              }}
            >
              In the text below some words are missing. Drag words from the box
              below to the appropriate place in the text.
            </Typography>

            <DragDropContext onDragEnd={handleDragEnd}>
              {/* Question Text */}
              <Box sx={{ mt: 4, lineHeight: 1.8, fontSize: "16px" }}>
                <Typography
                  component="div"
                  sx={{ color: "rgba(255,255,255,0.9)" }}
                >
                  {currentQuestion &&
                    (() => {
                      const { parts } = processQuestionText(
                        currentQuestion.question_text
                      );
                      return parts.map((part, index, array) => (
                        <React.Fragment key={index}>
                          {part}
                          {index < array.length - 1 &&
                            renderBlank(`blank${index + 1}`)}
                        </React.Fragment>
                      ));
                    })()}
                </Typography>
              </Box>

              {/* Draggable words section */}
              <Droppable droppableId="availableWords" direction="horizontal">
                {(provided) => (
                  <Box
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 1,
                      mt: 4,
                      p: 2,
                      bgcolor: "rgba(255,255,255,0.05)",
                      borderRadius: 1,
                    }}
                  >
                    {availableWords.map((word, index) => (
                      <Draggable
                        key={word.id}
                        draggableId={word.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <Box
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            sx={{
                              p: 1,
                              bgcolor: "#262842",
                              borderRadius: 1,
                              cursor: "grab",
                              boxShadow: snapshot.isDragging ? 3 : 0,
                              "&:hover": {
                                bgcolor: "#2F315A",
                              },
                            }}
                          >
                            {word.content}
                          </Box>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </DragDropContext>

            {/* Feedback Message after submission */}
            {isSubmitted && (
              <Box
                sx={{
                  mt: 3,
                  p: 2,
                  bgcolor: isCorrect
                    ? "rgba(76, 175, 80, 0.1)"
                    : "rgba(244, 67, 54, 0.1)",
                  borderRadius: 1,
                  border: `1px solid ${isCorrect ? "#4CAF50" : "#f44336"}`,
                }}
              >
                <Typography
                  sx={{
                    color: isCorrect ? "#4CAF50" : "#f44336",
                    fontWeight: "bold",
                  }}
                >
                  {isCorrect ? "Correct!" : "Incorrect. Try again!"}
                </Typography>
              </Box>
            )}

            {/* Answer Section */}
            {showAnswer && (
              <Box
                sx={{
                  mt: 3,
                  p: 2,
                  bgcolor: "rgba(0,166,147,0.1)",
                  borderRadius: 1,
                  border: "1px solid #00A693",
                }}
              >
                <Typography
                  sx={{ color: "#00A693", fontWeight: "bold", mb: 1 }}
                >
                  Correct Answer:
                </Typography>
                <Typography sx={{ color: "rgba(255,255,255,0.9)" }}>
                  {currentQuestion.FillInTheBlanksAnswers.join(", ")}
                </Typography>
              </Box>
            )}
          </>
        )}
      </Box>

      {/* Fixed Footer */}
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          bgcolor: "#1A1B2E",
          borderTop: "1px solid rgba(255,255,255,0.1)",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 2,
          zIndex: 1000,
        }}
      >
        <FormControlLabel
          control={
            <Switch
              checked={showAnswer}
              onChange={(e) => setShowAnswer(e.target.checked)}
              sx={{
                "& .MuiSwitch-switchBase.Mui-checked": {
                  color: "#00A693",
                },
                "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                  backgroundColor: "#00A693",
                },
              }}
            />
          }
          label="Show Answer"
          sx={{ color: "#fff" }}
        />

        <Button
          variant="contained"
          onClick={handleSubmit}
          sx={{
            bgcolor: "#00A693",
            "&:hover": {
              bgcolor: "#009688",
            },
            textTransform: "none",
            px: 4,
            py: 1,
            fontSize: "16px",
            borderRadius: "4px",
            minWidth: "120px",
            color: "#fff",
          }}
        >
          Submit
        </Button>
      </Box>

      {/* Search Modal */}
      <Modal
        open={openSearchModal}
        onClose={() => setOpenSearchModal(false)}
        aria-labelledby="search-modal"
      >
        {/* Modal content remains the same */}
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "#1A1B2E",
            borderRadius: "8px",
            p: 3,
            outline: "none",
            maxHeight: "80vh",
            overflow: "auto",
          }}
        >
          {/* Modal Header */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 3,
              borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
              pb: 2,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Select
                value="All"
                size="small"
                sx={{
                  color: "#fff",
                  height: "32px",
                  minWidth: "100px",
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(255,255,255,0.3)",
                    borderRadius: "4px",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(255,255,255,0.5)",
                  },
                }}
              >
                <MenuItem value="All">All</MenuItem>
              </Select>
              <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                {79} Qs
              </Typography>
            </Box>
            <IconButton
              onClick={() => setOpenSearchModal(false)}
              sx={{
                color: "#fff",
                p: 1,
                "&:hover": { bgcolor: "rgba(255,255,255,0.1)" },
              }}
            >
              <i className="fas fa-times" />
            </IconButton>
          </Box>

          {/* Search Items */}
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            {searchItems.map((item, index) => (
              <Box
                key={index}
                sx={{
                  bgcolor: "#262842",
                  borderRadius: "8px",
                  p: 2,
                  cursor: "pointer",
                  "&:hover": {
                    bgcolor: "#2F315A",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                    {index + 1}. {item.id}
                  </Typography>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Box
                      sx={{
                        bgcolor: "#FFC107",
                        px: 1,
                        borderRadius: "4px",
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "#000",
                      }}
                    >
                      New
                    </Box>
                    <Box
                      sx={{
                        bgcolor: "#FFC107",
                        px: 1,
                        borderRadius: "4px",
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "#000",
                      }}
                    >
                      VIP
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ mt: 1 }}>
                  <Typography
                    sx={{
                      color: "rgba(255,255,255,0.6)",
                      fontSize: "12px",
                      display: "flex",
                      alignItems: "center",
                      gap: 0.5,
                    }}
                  >
                    Attempted: {item.attempted}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default FillInBlanks;

import Introduction from 'layouts/mocktest/Introduction';
import FillInBlanksAnswerMock from 'layouts/mocktest/listening/FTBQ/answers';
import HighlightSummaryAnswerMock from 'layouts/mocktest/listening/HSQ/answers';
import IncorrectWordsMock from 'layouts/mocktest/listening/IWQ/answers';
import MultipleAnswerListeningMock from 'layouts/mocktest/listening/MA/answers';
import MissingWordAnswerMock from 'layouts/mocktest/listening/MWQ/answers';
import SingleAnswerListeningMock from 'layouts/mocktest/listening/SA/answers';
import SummarizeTextAcedimicMock from 'layouts/mocktest/listening/STA/answers';
import DictationAnswersMock from 'layouts/mocktest/listening/WFD/dictationanswers';

import FillInBlanksMock from 'layouts/mocktest/reading/FITB/answers';
import MultipleAnswerReadingMock from 'layouts/mocktest/reading/MA/answers';
import ReorderParagraphsMock from 'layouts/mocktest/reading/ROP/answers';
import FillInBlanksSelectMock from 'layouts/mocktest/reading/RWF/answers';
import SingleAnswerReadingMock from 'layouts/mocktest/reading/SA/answers';

import ImageDescriptionMock from 'layouts/mocktest/speaking/DIQ/answers';
import ReadAloudMock from 'layouts/mocktest/speaking/RAQ/answers';
import RepeatSentenceAnswersMock from 'layouts/mocktest/speaking/RS/answers';
import RespondToSituationMock from 'layouts/mocktest/speaking/RTS/answers';
import ShortAnswerMock from 'layouts/mocktest/speaking/SAQ/answers';
// import EssayAnswersMock from 'layouts/mocktest/writing/ESSAY/answers';

import WriteEmailMock from 'layouts/mocktest/writing/WE/answers';
import React, { useEffect, useState } from 'react';
import { API_PATH } from 'services/apipath';
import { postApi } from 'services/axiosInstance';

export default function FullMockTest({ category, subcategory, base }) {
    console.log(category, "category", subcategory, "subcategory", base, "base");

    const [timeRemaining, setTimeRemaining] = useState(8400); // Total test duration in seconds (2 hrs 20 mins)
    const [currentPart, setCurrentPart] = useState(null);
    const [currentQuestions, setCurrentQuestions] = useState([]);
    const [questionsData, setQuestionsData] = useState({});
    const [initialComponent, setInitialComponent] = useState(null);

    // Define time intervals and parts
    const timeIntervals = [
        { start: 0, end: 4020, part: 'Speaking and Writing' }, // 54–67 minutes
        { start: 4021, end: 5820, part: 'Reading' }, // 29–30 minutes
        { start: 5821, end: 8400, part: 'Listening (Part 3)' }, // 30–43 minutes
    ];

    // Local state for category and subcategory
    const [currentCategory, setCurrentCategory] = useState(category); // Default category
    const [currentSubCategory, setCurrentSubcategory] = useState(subcategory); // Default subcategory

    const handleNext = () => {
        const subcategories = progressionMap[currentCategory];
        const currentIndex = subcategories.indexOf(currentSubCategory);

        if (currentIndex < subcategories.length - 1) {
            // Move to the next subcategory within the same category
            setCurrentSubcategory(subcategories[currentIndex + 1]);
        } else {
            // Move to the next category
            const categoryIndex = categoryOrder.indexOf(category);
            if (categoryIndex < categoryOrder.length - 1) {
                const nextCategory = categoryOrder[categoryIndex + 1];
                setCurrentCategory(nextCategory);
                setCurrentSubcategory(progressionMap[nextCategory][0]); // Start with the first subcategory of the next category
            }
        }
    };
    // Define the category-subcategory progression
    const progressionMap = {
        Speaking: ['Read Aloud', 'Repeat Sentence', 'Describe Image', 'Respond to Situation', 'Short Answer'],
        Writing: [ 'Summarize Text', 'Write Email'],
        Reading: ['RW Fill in Blanks', 'Multiple Answers', 'Reorder Paragraph', 'Fill in Blanks', 'Single Answer'],
        Listening: ['Summarize Text', 'Multiple Answers', 'Fill in the Blanks', 'Single Answer', 'Missing Words', 'Incorrect Words'],
    };

    const categoryOrder = ['Speaking', 'Writing', 'Reading', 'Listening']; // Order of categories
    // Define the question structure for each part
    const questionStructure = {
        "Speaking and Writing": [
            { type: 'Read Aloud', count: 7 },
            { type: 'Repeat Sentence', count: 6 },
            { type: 'Describe Image', count: 5 },
            { type: 'Respond to Situation', count: 5 },
            { type: 'Short Answer', count: 5 },
            { type: 'Summarize Text', count: 1 },
            { type: 'Write Email', count: 1 },
        ],
       " Reading (Part 2)": [
            { type: 'RW Fill in Blanks', count: 6 },
            { type: 'Multiple Answers', count: 1 },
            { type: 'Reorder Paragraph', count: 2 },
            { type: 'Fill in Blanks', count: 4 },
            { type: 'Single Answer', count: 2 },
        ],
        "Listening (Part 3)": [
            { type: 'Summarize Text', count: 1 },
            { type: 'Multiple Answers', count: 2 },
            { type: 'Fill in the Blanks', count: 2 },
            { type: 'Single Answer', count: 2 },
            { type: 'Missing Words', count: 1 },
            { type: 'Incorrect Words', count: 2 },
        ],
    };

    const categorySubcategoryMap = {
        Speaking: {
            "Read Aloud": <ReadAloudMock questionData={questionsData} onNext={handleNext} />,
            "Repeat Sentence": <RepeatSentenceAnswersMock questionData={questionsData} onNext={handleNext} />,
            "Describe Image": <ImageDescriptionMock questionData={questionsData} onNext={handleNext} />,
            "Respond to Situation": <RespondToSituationMock questionData={questionsData} onNext={handleNext} />,
            "Short Answer": <ShortAnswerMock questionData={questionsData} onNext={handleNext} />,
        },
        Writing: {
            // "Essay": <EssayAnswersMock questionData={questionsData} onNext={handleNext} />,
            "Summarize Text": <SummarizeTextAcedimicMock questionData={questionsData} onNext={handleNext} />,
            "Write Email": <WriteEmailMock questionData={questionsData} onNext={handleNext} />,
        },
        Reading: {
            "RW Fill in Blanks": <FillInBlanksSelectMock questionData={questionsData} onNext={handleNext} />,
            "Multiple Answers": <MultipleAnswerReadingMock questionData={questionsData} onNext={handleNext} />,
            "Reorder Paragraph": <ReorderParagraphsMock questionData={questionsData} onNext={handleNext} />,
            "Fill in Blanks": <FillInBlanksMock questionData={questionsData} onNext={handleNext} />,
            "Single Answer": <SingleAnswerReadingMock questionData={questionsData} onNext={handleNext} />,
        },
        Listening: {
            "Single Answer": <SingleAnswerListeningMock questionData={questionsData} onNext={handleNext} />,
            "Multiple Answers": <MultipleAnswerListeningMock questionData={questionsData} onNext={handleNext} />,
            "Write From Dictation": <DictationAnswersMock questionData={questionsData} onNext={handleNext} />,
            "Highlight Summary": <HighlightSummaryAnswerMock questionData={questionsData} onNext={handleNext} />,
            "Incorrect Words": <IncorrectWordsMock questionData={questionsData} onNext={handleNext} />,
            "Fill in the Blanks": <FillInBlanksAnswerMock questionData={questionsData} onNext={handleNext} />,
            "Missing Words": <MissingWordAnswerMock questionData={questionsData} onNext={handleNext} />,
            "Summarize Text": <SummarizeTextAcedimicMock questionData={questionsData} onNext={handleNext} />,
        }
    };

    const fetchData = async () => {
        try {
            const res = await postApi(
                `${API_PATH.STUDENTS.GET_QUESTIONS_IDS_BY_CATEGORY}/${currentCategory}`,
                { subcategory: currentSubCategory, base: base !== 'undefined' ? base : '' }
            );
            if (res.status === 200) {
                setQuestionsData(res.data.data);
            }
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };


    const PickPage = () => {
        console.log(questionsData, "questionData in full mock test");

        const categoryMap = categorySubcategoryMap[currentCategory];
        if (categoryMap) {
            const component = categoryMap[currentSubCategory];
            if (component) {
                setInitialComponent(component);
            }
        } else {
            setInitialComponent(<div>No component for this category</div>);
        }
    };

    // Determine the current part based on timeRemaining
    useEffect(() => {
        const interval = timeIntervals.find(
            (interval) => timeRemaining >= interval.start && timeRemaining <= interval.end
        );

        if (interval) {
            setCurrentPart(interval.part);
            setCurrentQuestions(questionStructure[interval.part]);

            // Update the category and subcategory based on the current part
            const categoryIndex = categoryOrder.findIndex((cat) =>
                Object.keys(progressionMap[cat]).includes(interval.part)
            );

            if (categoryIndex !== -1) {
                const newCategory = categoryOrder[categoryIndex];
                setCurrentCategory(newCategory);

                // Get the subcategory progression for the selected category
                const subcategories = progressionMap[newCategory];

                if (subcategories) {
                    // Dynamically determine the subcategory to display
                    const elapsedPartTime = timeRemaining - interval.start;
                    const subcategoryIndex = Math.floor(
                        (elapsedPartTime / (interval.end - interval.start)) * subcategories.length
                    );

                    setCurrentSubcategory(subcategories[subcategoryIndex]);
                }
            }
        }
    }, [timeRemaining]);


    useEffect(() => {
        fetchData();  // fetch new data when category, subcategory, or base changes
    }, [currentCategory, currentSubCategory, base]);

    useEffect(() => {
        if (Object.keys(questionsData).length > 0) {
            PickPage();  // Update component once questionsData is fetched
        }
    }, [questionsData]);

    return (
        <React.Fragment>
            {/* <Introduction /> */}
            {initialComponent}
        </React.Fragment>
    );
}

import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  IconButton,
  Button,
  Container,
  Modal
} from "@mui/material";
import { Switch } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { getApi } from "services/axiosInstance";
import { API_PATH } from "services/apipath";
import { postApi } from "services/axiosInstance";
import { CheckCircle } from "lucide-react";
import { AlignVerticalBottomOutlined } from "@mui/icons-material";

const total = 15.0;
function EssayAnswers({ questionData }) {
  const timeLimit = 20;
  const wordLimit = { min: 200, max: 300 };
  const [text, setText] = useState("");
  const [wordCount, setWordCount] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState(timeLimit * 60);
  const [isPaused, setIsPaused] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [relevantKeywords, setRelevantKeywords] = useState([]);
  const [studentText, setStudentText] = useState("");
  const [sampleText, setSampleText] = useState("");
  const [errors, setErrors] = useState([]);
  // const [wordCount, setWordCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showSampleAnswer, setShowSampleAnswer] = useState(false);
  const [scores, setScores] = useState({
    content: 0,
    form: 0,
    grammar: 0,
    vocabulary: 0,
    spelling: 0,
    linguistic: 0,
    development: 0,
  });

  useEffect(() => {
    if (isPaused || timeRemaining <= 0) return;

    const timer = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(timer);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
    return () => clearInterval(timer);
  }, [isPaused, timeRemaining]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  const pauseTimer = () => {
    setIsPaused(true);
  };

  const handleTextChange = (newText) => {
    setStudentText(newText);
    const words = newText.trim().split(/\s+/).filter(Boolean).length;
    setWordCount(words);
  };

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const Category = params.get("category");
  let id = location.pathname.split("/")[2];

  const navigate = useNavigate();

  const findIndex = () => {
    const questionIndex = questionData.questions?.findIndex(
      (question) => question.id === id
    );

    setCurrentIndex(questionIndex);
    setCurrentQuestion(questionIndex + 1);
    if (questionIndex === -1) {
    } else {
    }
  };

  useEffect(() => {
    if (questionData) {
      findIndex();
    }
  }, [questionData]);

  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [submitedAns, setSubmitedAns] = useState(null);

  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [scoreButton, setScoreButton] = useState(false);
  const [openAnalysisModal, setOpenAnalysisModal] = useState(false);

  const [questionid, setQuestionId] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [questionDataApi, setQuestionDataApi] = useState([]);

  const getQuestionBtID = async (id) => {
    try {
      const res = await getApi(`${API_PATH.STUDENTS.GET_QUESTION_BY_ID}/${id}`);
      if (res.status === 200) {
        setQuestionDataApi(res.data.data);
        setSampleText(res.data.data.answer);
        setRelevantKeywords(res.data.data.keyWords);
      }
    } catch (error) { }
  };

  useEffect(() => {
    if (id) {
      getQuestionBtID(id);
    }
  }, []);

  const handleNext = async () => {
    if (currentQuestion === questionData.totalQuestions) {
      alert("Congratulations You have completed all questions!");
      return;
    } else {
      setCurrentQuestion(currentQuestion + 1);
      setCurrentIndex(currentIndex + 1);
      getQuestionBtID(questionData.questions[currentIndex + 1].id);
      setSubmitedAns(null);
      setSelectedAnswer(null);
    }
  };

  const handlePrev = async () => {
    if (currentQuestion > 1) {
      setCurrentQuestion(currentQuestion - 1);
      setCurrentIndex(currentIndex - 1);
      setQuestionId(questionData.questions[currentIndex - 1].id);
      getQuestionBtID(questionData.questions[currentIndex - 1].id);
      setSubmitedAns(null);
      setSelectedAnswer(null);
    }
  };

  const [checked, setChecked] = useState(false);

  const handleToggle = (event) => {
    setChecked(event.target.checked);
  };

  const checkRelevancy = (text) => {
    const textLower = text.toLowerCase();
    const foundKeywords = relevantKeywords.filter((keyword) =>
      textLower.includes(keyword.toLowerCase())
    );
    return {
      isRelevant: foundKeywords.length >= 2,
      keywordCount: foundKeywords.length,
      foundKeywords,
    };
  };
  const checkDevelopment = (studentText, sampleText) => {
    const studentWordCount = studentText.split(/\s+/).length;
    const sampleWordCount = sampleText.split(/\s+/).length;
    if (studentWordCount <= sampleWordCount) {
      return 0; // Return 0 if the development is minimal
    }

    const wordCountDifference = studentWordCount / sampleWordCount;

    if (wordCountDifference >= 1.2) {
      return 90;
    } else if (wordCountDifference >= 1) {
      return 70;
    }

    return 0;
  };

  const evaluateGrammarSpelling = async (text) => {
    try {
      setLoading(true);
      try {
        const response = await postApi(API_PATH.STUDENTS.CHECK_GRAMMAR, {
          text,
        });
        setLoading(false);

        const result = response.data.result;
        setErrors(result.edits);

        setWordCount(
          text.split(/\s+/).filter((word) => word.length > 0).length
        );
        setLoading(false);
        return result || [];
      } catch (error) {
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const [highlightedText, setHighlightedText] = useState(false);
  const HighlightedText = ({ text, errors }) => {
    if (!text) return null;

    let displayText = text;
    const textParts = [];
    let lastIndex = 0;

    const sortedErrors = [...errors].sort((a, b) => a.start - b.start);

    sortedErrors.forEach((error) => {
      const { start, end, replace, err_cat } = error;
      const errorType = err_cat === "GRMR" ? "text-warning" : "text-danger";

      if (start > lastIndex) {
        textParts.push(
          <span key={`normal-${lastIndex}`} className="text-success">
            {text.slice(lastIndex, start)}
          </span>
        );
      }

      textParts.push(
        <span key={`error-${start}`} className={`p-1 ${errorType}`}>
          {replace || text.slice(start, end)}{" "}
        </span>
      );

      lastIndex = end;
    });

    if (lastIndex < text.length) {
      textParts.push(
        <span key={`normal-end`} className="text-success">
          {text.slice(lastIndex)}
        </span>
      );
    }

    return <div className=" p-2 rounded highLightText">{textParts}</div>;
  };

  const evaluateText = async () => {
    setLoading(true);
    const apiResponse = await evaluateGrammarSpelling(studentText);
    const grammarErrors = apiResponse.edits;
    const relevancy = checkRelevancy(studentText);
    const developmentScore = checkDevelopment(studentText, sampleText);

    let newScores = {
      overall: 0,
      content: 0,
      form: 0,
      grammar: 0,
      vocabulary: 0,
      spelling: 0,
      linguistic: 0,
      development: 0,
    };

    if (relevancy.isRelevant) {
      const baseScore = 90;
      const errorPenalty = grammarErrors.length * 5;
      const contentScore = Math.max(0, baseScore - errorPenalty);
      const formScore = Math.max(0, baseScore - errorPenalty / 2);
      const grammarScore = Math.max(
        0,
        baseScore -
        grammarErrors.filter((e) => e.rule?.category === "GRMR").length * 10
      );
      const vocabularyScore = Math.max(
        0,
        (relevancy.keywordCount / relevantKeywords.length) * 100
      );
      const spellingScore = Math.max(
        0,
        baseScore -
        grammarErrors.filter((e) => e.rule?.category === "SPELL").length * 10
      );
      const linguisticScore = Math.max(0, baseScore - errorPenalty / 3);
      const developmentScoreOutOf100 = developmentScore;
      const contentScoreOutOf2 = Math.round((contentScore / 100) * 3);
      const formScoreOutOf2 = Math.round((formScore / 100) * 2);
      const grammarScoreOutOf2 = Math.round((grammarScore / 100) * 2);
      const vocabularyScoreOutOf2 = Math.round((vocabularyScore / 100) * 2);
      const spellingScoreOutOf2 = Math.round((spellingScore / 100) * 2);
      const linguisticScoreOutOf2 = Math.round((linguisticScore / 100) * 2);
      const developmentScoreOutOf2 = Math.round(
        (developmentScoreOutOf100 / 100) * 2
      );

      newScores = {
        overall:
          parseFloat(contentScoreOutOf2) +
          parseFloat(formScoreOutOf2) +
          parseFloat(grammarScoreOutOf2) +
          parseFloat(vocabularyScoreOutOf2) +
          parseFloat(spellingScoreOutOf2) +
          parseFloat(linguisticScoreOutOf2) +
          parseFloat(developmentScoreOutOf2),
        content: contentScoreOutOf2, // out of 2
        form: formScoreOutOf2, // out of 2
        grammar: grammarScoreOutOf2, // out of 2
        vocabulary: vocabularyScoreOutOf2, // out of 2
        spelling: spellingScoreOutOf2, // out of 2
        linguistic: linguisticScoreOutOf2, // out of 2
        development: developmentScoreOutOf2, // out of 2
      };
    }
    setScores(newScores);
    setErrors(grammarErrors);
    setWordCount(
      studentText.split(/\s+/).filter((word) => word.length > 0).length
    );
    const score = {
      total: total,
      overall: newScores.overall,
      content: newScores.content,
      form: newScores.form,
      grammar: newScores.grammar,
      spelling: newScores.spelling,
      linguistic: newScores.linguistic,
      vocabulary: newScores.vocabulary,
      development: newScores.development,
      text: studentText,
    };
    const payload = {
      questionId: id,
      stringAns: studentText,
      correctAnswers: sampleText,
      score: JSON.stringify(score),
    };
    setHighlightedText(true);
    try {
      const answer = await postApi(API_PATH.STUDENTS.SUBMIT_ANS, payload);
      if (answer.status) {
        setScoreButton(true);
      }
    } catch (error) { }

    setLoading(false);
  };

  const highlightErrors = (text, errors) => {
    let highlightedText = text;
    errors.sort((a, b) => b.offset - a.offset);

    errors.forEach((error) => {
      const { offset, length, rule } = error;
      const errorText = text.substring(offset, offset + length);

      const color = rule.category.name === "Grammar" ? "red" : "yellow"; // Grammar = red, Spelling = yellow

      const highlightedError = `<span style="background-color: ${color};">${errorText}</span>`;
      highlightedText =
        highlightedText.slice(0, offset) +
        highlightedError +
        highlightedText.slice(offset + length);
    });

    return highlightedText;
  };

  const ScoreCard = ({ label, score }) => (
    <div className="d-flex justify-content-between p-2 border rounded">
      <span className="font-weight-semibold">{label}</span>
      <div className="d-flex align-items-center gap-2">
        <span
          className={`${score >= 70 ? "text-success" : "text-danger"
            } font-weight-bold`}
        >
          {score.toFixed(1)}
        </span>
        {score >= 70 ? (
          <CheckCircle className="w-4 h-4 text-success" />
        ) : (
          <AlignVerticalBottomOutlined className="w-4 h-4 text-danger" />
        )}
      </div>
    </div>
  );

  return (
    <Box sx={{ bgcolor: "#1A1F2E", minHeight: "100vh" }}>
      {/* Header */}
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bgcolor: "#1A1B2E",
          zIndex: 1000,
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              p: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid rgba(255,255,255,0.1)",
            }}
          >
            <IconButton sx={{ color: "#fff" }} onClick={() => navigate(-1)}>
              <i className="fas fa-arrow-left" />
            </IconButton>

            <Typography variant="h6" sx={{ color: "#fff", ml: "20px" }}>
              Essay
            </Typography>

            <Box
              sx={{ display: "flex", alignItems: "center", gap: 1, ml: "auto" }}
            >
              <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                {currentQuestion}/{questionData.totalQuestions}
              </Typography>

              <IconButton sx={{ color: "#fff" }} onClick={() => handlePrev()}>
                <i className="fas fa-chevron-left" />
              </IconButton>
              <IconButton
                sx={{ color: "#fff" }}
                onClick={() => setOpenSearchModal(true)}
              >
                <i className="fas fa-search" />
              </IconButton>
              <IconButton sx={{ color: "#fff" }}>
                <i className="fas fa-rotate" />
              </IconButton>
              <IconButton sx={{ color: "#fff" }} onClick={() => handleNext()}>
                <i className="fas fa-chevron-right" />
              </IconButton>
            </Box>
          </Box>
        </Container>
      </Box>

      <Container maxWidth="lg" sx={{ mt: 9, pb: "20px" }}>
        <Typography
          sx={{
            color: "#fff",
            fontStyle: "italic",
            mb: 2,
            fontSize: "17px",
          }}
        >
          You will have 20 minutes to plan, write and revise an essay about the
          topic below. Your response will be judged on how well you develop a
          position, organize your ideas, present supporting details, and control
          the elements of standard written English. You should write 200-300
          words.
        </Typography>

        {/* Question */}
        <Box
          sx={{
            bgcolor: "rgba(255,255,255,0.1)",
            p: 2,
            borderRadius: 1,
            mb: 3,
            width: "100%",
          }}
        >
          <Typography
            sx={{
              color: "#fff",
              wordWrap: "break-word",
              overflowWrap: "break-word",
              fontSize: "18px",
            }}
          >
            {questionDataApi.question_text}
          </Typography>
        </Box>

        {/* Answer Box */}
        <Box
          sx={{
            bgcolor: "#fff",
            borderRadius: 1,
            p: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography>Answer</Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography>words: {wordCount}</Typography>
            </Box>
          </Box>

          {!highlightedText && (
            <TextField
              fullWidth
              multiline
              rows={10}
              value={studentText}
              className="text-dark text-stroke"
              onChange={(e) => handleTextChange(e.target.value)}
              placeholder="Enter your response here..."
              disabled={timeRemaining === 0 || isSubmitting}
              sx={{
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "#E8E9F3 !important",
                  width: "100% !important",
                  "&:hover": {
                    backgroundColor: "#E8E9F3 !important",
                  },
                  "& fieldset": {
                    borderColor: "#E0E0E0",
                  },
                },
                "& .MuiOutlinedInput-input": {
                  width: "100% !important",
                  backgroundColor: "#E8E9F3 !important",
                },
                "&:disabled": {
                  color: "black !important",
                },
              }}
            />
          )}

          {highlightedText && (
            <HighlightedText text={studentText} errors={errors} />
          )}

          <Box
            display="flex"
            alignItems="center"
            sx={{
              padding: "10px 20px",
              borderRadius: "8px",
            }}
          >
            <div>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "600",
                  marginBottom: "16px",
                }}
              ></Typography>

              <div style={{ display: "flex", alignItems: "center" }}>
                <Switch
                  checked={checked}
                  onChange={handleToggle}
                  color="success"
                />
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    marginLeft: "8px",
                  }}
                >
                  Show Answer
                </Typography>
              </div>

              {checked && (
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    marginTop: "16px",
                    backgroundColor: "#f9f9f9",
                    padding: "12px",
                    borderRadius: "8px",
                    border: "1px solid #e0e0e0",
                  }}
                >
                  {questionDataApi.answer}
                </Typography>
              )}
            </div>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 2,
            px: 2,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography sx={{ color: "#fff" }}>Time remaining</Typography>
            <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
              {formatTime(timeRemaining)}
            </Typography>
          </Box>
          {isSubmitting && !scoreButton && (
            <Button
              onClick={() => evaluateText()}
              sx={{
                mt: 2,
                bgcolor: "#00BFA6",
                color: "#fff !important",
                borderRadius: "8px",
                p: 2,
                "&:hover": { bgcolor: "#009e8b", color: "#fff" },
              }}
            >
              Analyze Answer
            </Button>
          )}

          {!isSubmitting && !scoreButton && (
            <Button
              onClick={() => {
                setIsSubmitting(true);
                pauseTimer();
              }}
              sx={{
                mt: 2,
                bgcolor: "#00BFA6",
                color: "#fff !important",
                borderRadius: "8px",
                p: 2,
                "&:hover": { bgcolor: "#009e8b", color: "#fff" },
              }}
            >
              Submit Answer
            </Button>
          )}

          {isSubmitting && scoreButton && (
            <Button
              onClick={() => {
                setOpenAnalysisModal(true);
              }}
              sx={{
                mt: 2,
                bgcolor: "#00BFA6",
                color: "#fff !important",
                borderRadius: "8px",
                p: 2,
                "&:hover": { bgcolor: "#009e8b", color: "#fff" },
              }}
            >
              View Score
            </Button>
          )}
        </Box>

        {/* Analysis Modal */}
        <Modal
          open={openAnalysisModal}
          onClose={() => setOpenAnalysisModal(false)}
          aria-labelledby="analysis-modal"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "auto",
              bgcolor: "#FFFFFF",
              borderRadius: "12px",
              p: 2,
              outline: "none",
              textAlign: "center",
            }}
          >
            {/* Green Checkmark Circle */}
            <Box
              sx={{
                width: "64px",
                height: "64px",
                bgcolor: "#00E676",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "0 auto 0px",
              }}
            >
              <i
                className="fas fa-check"
                style={{ color: "#FFFFFF", fontSize: "32px" }}
              />
            </Box>

            <Typography
              variant="h6"
              sx={{
                color: "#000000",
                fontSize: "18px",
                fontWeight: 500,
                mb: 0,
                textAlign: "center",
              }}
            >
              Score: {scores.overall}/{total}
            </Typography>

            <Box
              sx={{
                mb: 0,
                textAlign: "center",
                display: "inline-block",
                fontSize: "16px",
              }}
            >
              {[
                { label: "Content", score: scores.content, total: 3 },
                { label: "Form", score: scores.form, total: 2 },
                { label: "Grammar", score: scores.grammar, total: 2 },
                { label: "Vocabulary", score: scores.vocabulary, total: 2 },
                { label: "Spelling", score: scores.spelling, total: 2 },
                { label: "Linguistic", score: scores.linguistic, total: 2 },
                { label: "Development", score: scores.development, total: 2 },
              ].map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: 1,
                    width: "450px",
                  }}
                >
                  <Typography
                    sx={{
                      flex: 1,
                      textAlign: "left",
                      color: "#666666",
                      width: "20%",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    {item.label}
                  </Typography>

                  <Box
                    sx={{
                      flex: 2,
                      height: "6px",
                      bgcolor: "#F0F0F0",
                      borderRadius: "3px",
                      position: "relative",
                      mx: 2,
                      width: "60%",
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        height: "100%",
                        width: `${(item.score / item.total) * 100}%`,
                        bgcolor: "#00E676",
                        borderRadius: "3px",
                      }}
                    />
                  </Box>

                  <Typography
                    sx={{ color: "#666666", minWidth: "70px", width: "20%" }}>
                    {item.score}/{item.total}
                  </Typography>
                </Box>
              ))}
            </Box>

            {checkDevelopment(studentText, sampleText) === 0 && (
              <div className="alert alert-warning mt-3 p-2 fs-6">
                Response needs more development compared to the sample answer.
              </div>
            )}
            <Button
              fullWidth
              sx={{
                bgcolor: "#00E676",
                color: "#FFFFFF",
                borderRadius: "25px",
                py: 1,
                textTransform: "none",
                fontSize: "16px",
                "&:hover": {
                  bgcolor: "#00C853",
                },
              }}
              onClick={() => setOpenAnalysisModal(false)}
            >
              Ok
            </Button>
          </Box>
        </Modal>
      </Container>
    </Box>
  );
}
export default EssayAnswers;
import { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MicIcon from "@mui/icons-material/Mic";
import TvIcon from "@mui/icons-material/Tv";
import colors from "assets/theme/base/colors";

const { dark, light, success } = colors;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  borderRadius: 5,
  borderWidth: 3,
  borderColor: dark.main,
  borderStyle: "solid",
};

export default function TestRecorderModal({ open, setOpen, openStartModal }) {
  const [resolution, setResolution] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setResolution({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleClose = () => setOpen(false);
  const handleOpenStart = () => {
    handleClose();
    openStartModal()
  };

  const [isRecording, setIsRecording] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const mediaRecorderRef = useRef(null);
  const audioChunks = useRef([]);

  const handleStartRecording = async () => {
    if (!isRecording) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        mediaRecorderRef.current = new MediaRecorder(stream);
        audioChunks.current = [];

        mediaRecorderRef.current.ondataavailable = (event) => {
          audioChunks.current.push(event.data);
        };

        mediaRecorderRef.current.onstop = () => {
          const audioBlob = new Blob(audioChunks.current, { type: "audio/webm" });
          const url = URL.createObjectURL(audioBlob);
          setAudioUrl(url);
        };

        mediaRecorderRef.current.start();
        setIsRecording(true);
      } catch (err) {
        console.error("Error accessing microphone:", err);
      }
    } else {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <Box
              sx={{
                display: "flex",
                p: 2,
                width: "100%",
                borderBottomWidth: 3,
                borderBottomColor: light.main,
                borderBottomStyle: "solid",
              }}
            >
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h6"
                sx={{
                  textAlign: "center",
                  fontSize: "1.5rem",
                  width: "95%",
                  ml: "5%",
                  fontWeight: "500",
                }}
              >
                System Requirements
              </Typography>
              <IconButton
                sx={{ width: "5%", p: 0 }}
                onClick={handleClose}
                aria-label="delete"
                size="large"
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                p: 5,
                gap: 5,
              }}
            >
              {/* Left Box */}
              <Box
                sx={{
                  bgcolor: "background.paper",
                  borderRadius: 5,
                  borderWidth: 3,
                  borderColor: "light.main",
                  borderStyle: "solid",
                  flex: 1,
                  p: 2.5,
                  pt: 4,
                  position: "relative",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "0%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    px: 2,
                    py: 1.5,
                    bgcolor: "background.paper",
                    borderRadius: "50%",
                    borderWidth: 2,
                    borderColor: "light.main",
                    borderStyle: "solid",
                    backgroundColor: "#3A416F !important",
                    color: "#fff",
                  }}
                >
                  <MicIcon fontSize="medium" />
                </Box>
                <Typography sx={{ fontSize: "1.25rem" }}>Test Mic</Typography>
                <Typography sx={{ fontSize: "0.8rem", color: "#292a2d" }}>
                  "Humans are only animals that blush'"
                </Typography>
                <Box sx={{ textAlign: "center" }}>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={handleStartRecording}
                    sx={{
                      bgcolor: isRecording ? "red" : "#4DAA54", // Red for "Stop Recording" and Green for "Start Recording"
                      fontSize: "0.9rem",
                      minWidth: "250px",
                      fontWeight: "500",
                      color: "#fff",
                      textTransform: "capitalize",
                      "&:hover": {
                        bgcolor: isRecording ? "red" : "#4DAA54", // Keep the same color on hover
                      },
                    }}
                  >
                    {isRecording ? "Stop Recording" : "Start Recording"}
                  </Button>

                </Box>
                {audioUrl && (
                  <Box sx={{ mt: 2 }}>
                    <audio controls src={audioUrl} />
                  </Box>
                )}

                {/* {audioUrl && (
                  <Box sx={{ mt: 2, textAlign: "center" }}>
                    <audio id="audio-player" src={audioUrl} />
                    <Box>
                      <Button
                        onClick={() => {
                          const audio = document.getElementById("audio-player");
                          if (audio.paused) {
                            audio.play();
                          } else {
                            audio.pause();
                          }
                        }}
                        variant="contained"
                        size="small"
                        sx={{
                          bgcolor: "#4DAA54",
                          fontSize: "0.9rem",
                          minWidth: "150px",
                          fontWeight: "500",
                          color: "#fff",
                          textTransform: "capitalize",
                        }}
                      >
                        Play / Pause
                      </Button>
                    </Box>
                  </Box>
                )} */}

              </Box>

              {/* Right Box */}
              <Box
                sx={{
                  bgcolor: "background.paper",
                  borderRadius: 5,
                  borderWidth: 3,
                  borderColor: light.main,
                  borderStyle: "solid",
                  flex: 1,
                  p: 2.5,
                  pt: 4,
                  position: "relative",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "0%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    px: 2,
                    py: 1.5,
                    bgcolor: "background.paper",
                    borderRadius: "50%",
                    borderWidth: 2,
                    borderColor: light.main,
                    borderStyle: "solid",
                    backgroundColor: "#3A416F !important",
                    color: "#fff"

                  }}
                >
                  <TvIcon fontSize="medium" />
                </Box>
                <Typography sx={{ fontSize: "1.25rem" }}>
                  Best Resolution
                </Typography>
                <Typography sx={{ fontSize: "0.8rem", fontWeight: "800" }}>
                  1280 x 720 and above
                </Typography>
                <Typography sx={{ fontSize: "0.8rem", color: "#292a2d" }}>
                  Your Resolution: {resolution.width} x {resolution.height}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ textAlign: "center", pb: 2.5 }}>
              <Button
                onClick={handleOpenStart}
                variant="contained"
                size="small"
                sx={{
                  bgcolor: success.main,
                  "&:hover": { bgcolor: success.focus },
                  fontSize: "0.9rem",
                  minWidth: "250px",
                  fontWeight: "500",
                  color: "#fff",
                  textTransform: "capitalize",
                  backgroundColor: "#3A416F !important"
                }}
              >
                Continue
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { Box, Chip, Tabs, Tab, Button } from "@mui/material";
import { API_PATH } from "services/apipath";
import moment from "moment";
import { postApi } from "services/axiosInstance";
import Pagination from "components/Pagination/Pagination";
import DeleteIcon from '@mui/icons-material/Delete';
import FastForwardIcon from '@mui/icons-material/FastForward';

// Static mock test history data
const mockTestHistoryData = [
  {
    attemptedAt: "2024-01-15T10:30:00",
    attemptCount: 1,
    questionId: {
      _id: "MT001",
      mock_test_title: "Full Test Mock Test",
    },
    score: {
      overall: 7,
      total: 9,
    },
  },
  {
    attemptedAt: "2024-01-20T14:45:00",
    attemptCount: 2,
    questionId: {
      _id: "MT002",
      mock_test_title: "Academic Reading Mock Test",
    },
    score: {
      overall: 6,
      total: 9,
    },
  },
];

function BillingInformation() {
  const [currentTab, setCurrentTab] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 10;

  const [practiceHistory, setPracticeHistory] = useState([]);
  const [mockTestHistory] = useState(mockTestHistoryData);
  const [totalPracticeHistory, setTotalPracticeHistory] = useState(0);
  const [totalMockTestHistory] = useState(mockTestHistoryData.length);

  const getHistoryData = async () => {
    try {
      const res = await postApi(API_PATH.STUDENTS.GET_ATTEMPT_PRACTICE, {
        page: currentPage,
        limit: ITEMS_PER_PAGE,
      });

      if (res.status === 200) {
        setPracticeHistory(res.data.data.attempts);
        setTotalPracticeHistory(res.data.data.totalData);
      }
    } catch (error) {
      console.error("Error fetching practice history:", error);
    }
  };

  useEffect(() => {
    if (currentTab === 0) {
      getHistoryData();
    }
  }, [currentPage, currentTab]);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
    setCurrentPage(1);
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
      window.scrollTo(0, 0);
    }
  };

  const totalPages =
    currentTab === 0
      ? Math.ceil(totalPracticeHistory / ITEMS_PER_PAGE)
      : Math.ceil(totalMockTestHistory / ITEMS_PER_PAGE);

  const currentHistory = currentTab === 0 ? practiceHistory : mockTestHistory;

  return (
    <Card id="attempt-history">
      <SoftBox pt={3} px={2}>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          aria-label="attempt history tabs"
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            py: 2,
          }}
          TabIndicatorProps={{
            sx: {
              backgroundColor: "lightgrey",
            },
          }}
        >
          <Tab
            sx={{
              padding: "18px 0",
              marginLeft: "15px",
              "&.Mui-selected": {
                color: "lightgrey",
              },
            }}
            label="Practice History"
          />
          <Tab
            sx={{
              padding: "18px 0",
              marginRight: "15px",
              "&.Mui-selected": {
                color: "lightgrey",
              },
            }}
            label="Mock Test History"
          />
        </Tabs>
      </SoftBox>

      <SoftBox pt={1} pb={2} px={2}>
        <SoftBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={0}
          m={0}
          sx={{
            height: "70vh",
            overflowY: "scroll",
          }}
        >
          {currentHistory.map((attempt, index) => (
            <SoftBox
              key={index}
              component="li"
              mb={3}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={2}
              borderRadius="lg"
              sx={{
                backgroundColor: "#F8F9FA",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            >
              <SoftBox display="flex" flexDirection="column">
                <SoftTypography
                  variant="caption"
                  fontWeight="regular"
                  color="black"
                >
                  Attempted:{" "}
                  {moment(attempt.attemptedAt).format("DD-MM-YYYY hh:mm a")}
                  <br />
                </SoftTypography>
                <SoftTypography
                  mt={2}
                  variant="caption"
                  fontWeight="regular"
                  color="black"
                >
                  Attempted Count: {attempt.attemptCount}
                </SoftTypography>
                <SoftTypography
                  variant="button"
                  fontWeight="medium"
                  color="error"
                >
                  {currentTab === 0
                    ? attempt.questionId.question_title
                    : attempt.questionId.mock_test_title}
                </SoftTypography>
              </SoftBox>

              <SoftBox display="flex" alignItems="center">
                <Chip
                  label={`${attempt.score?.overall}/${attempt.score?.total}`}
                  color={
                    attempt.score?.overall === attempt.score?.total
                      ? "success"
                      : "error"
                  }
                  variant="outlined"
                  sx={{
                    color: "white",
                    borderColor:
                      attempt.score?.overall === attempt.score?.total
                        ? "#4CAF50"
                        : "#F44336",
                    mr: 2,
                  }}
                />
                {currentTab === 1 && (
                  <>
                    <Button
                      variant="contained"
                      // color="success"
                      // size="small"
                      
                      sx={{ mr: 2, bgcolor:"green", color:"white !important" }}
                      startIcon={<FastForwardIcon  sx={{color:"black", fontSize:"22px"}}/>}
                      onClick={() =>
                        console.log(`Button 1 clicked for ${attempt.questionId._id}`)
                      }
                    >
                      Resume
                    </Button>
                    <Button
                      variant="contained"
                      // color="secondary"
                      // size="small"
                      sx={{bgcolor:"#F44336", color:"white !important"}}
                      startIcon={<DeleteIcon />}
                      onClick={() =>
                        console.log(`Button 2 clicked for ${attempt.questionId._id}`)
                      }
                    >
                      Delete
                    </Button>
                  </>
                )}
              </SoftBox>
            </SoftBox>
          ))}
        </SoftBox>
      </SoftBox>
      <Box
        sx={{
          p: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderTop: "1px solid rgba(255, 255, 255, 0.1)",
          bgcolor: "#1a1a2e",
        }}
      >
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </Box>
    </Card>
  );
}

export default BillingInformation;
